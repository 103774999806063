export class SubscriptionPricingPlanData {
    referenceCode: string;
    createdDate: string;
    name: string;
    price: number;
    paymentInterval: string;
    paymentIntervalCount: number;
    trialPeriodDays: number;
    currencyCode: string;
    productReferenceCode: string;
    planPaymentType: string;
    status: string;
    recurrenceCount: number;
}