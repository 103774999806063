import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecureService } from 'src/app/services/secure.service';

@Pipe({
    name: 'secure',
    pure: true
})
export class SecurePipe implements PipeTransform {

    constructor(private http: HttpClient, private sanitizer: DomSanitizer, private secureService: SecureService) { }

    transform(url: string): Observable<SafeUrl> {

        const lastElementOfUrl = url.split('/')[url.split('/').length - 1];

        if (lastElementOfUrl !== 'undefined' && url.split('/')[url.split('/').length - 1] !== '') {
            if (this.secureService.urlMap.has(url)) {
                return from(new Promise(resolve => {
                    resolve(this.secureService.urlMap.get(url));
                }));
            }
            else {
                return this.http
                    .get(url, { responseType: 'blob' })
                    .pipe(map(
                        (val) => {
                            const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(val));
                            this.secureService.urlMap.set(url, safeUrl);
                            return safeUrl;
                        }
                    ));
            }
        }
        else{
            return;
        }
    }
}
