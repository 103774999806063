import { Request } from "../../base/request";

export class RetrieveSubscriptionListWithOwnerIdRequest extends Request {

    private accountId: string;

    constructor(accoutnId: string) {
        super();
        this.accountId = accoutnId;
    }

};
