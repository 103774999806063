export class CreatedSubscriptionData {
    referenceCode: string;
    parentReferenceCode: string;
    pricingPlanReferenceCode: string;
    customerReferenceCode: string;
    subscriptionStatus: string;
    trialDays: number;
    trialStartDate: string;
    trialEndDate: string;
    canceledAt: string;
    createdDate: string;
    startDate: string;
    endDate: string;
}