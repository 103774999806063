import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { Product } from "src/app/domain/experience/product/product";
import { AuthService } from "../yon/auth/auth.service";

@Injectable()
export class ExperienceService {

    public subject = new Subject<string>();

    public product: Product;

    public glbLink: string = "";
    public usdzLink: string = "";

    constructor(private authService: AuthService) { }

    /**
     * sets glbLink and usdzLink for current product
     */
    setProductFileLinks(): void {
        // this.usdzLink = 'https://api.yon.tc/yon-media/media/download/' + this.product.usdzId + '?sessionId=' + this.authService.session['id'];
        // this.glbLink = 'intent://arvr.google.com/scene-viewer/1.0?file=' + 'https://api.yon.tc/yon-media/media/download/' + this.product.glbId + '?sessionId=' + this.authService.session['id'] + '#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;'
        this.subject.next("1")
    }
}