import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpManager } from 'src/app/managers/http.manager';
import { parameters } from 'src/app/parameters/parameters';
import { Organization } from 'src/app/domain/yon/auth/organization';
import { GenericRequest } from 'src/app/dto/request/generic-request.dto';
import { PageRequestDto } from 'src/app/dto/request/page-request.dto';
import { Account } from 'src/app/domain/yon/auth/account';

const childPath = 'organization/';

@Injectable()
export class OrganizationService {
    constructor(private httpManager: HttpManager) {

    }

    create(organization: Organization): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + 'create', organization).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    update(organization: Organization): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + 'update', organization).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    delete(organization: Organization): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + 'delete', organization).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    list(pageRequestDto: PageRequestDto): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + 'list', pageRequestDto).pipe(
            map(
                (res) => {
                    if (res != null) {
                        const account: Account = res;
                        return account;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    getById(genericReq: GenericRequest): Observable<any> {//Add Account Id to Generic Request's data attribute
        return this.httpManager.post(parameters.authServer + childPath + 'get-by-id', genericReq).pipe(
            map(
                (res) => res
            )
        );
    }

    search(pageRequest: PageRequestDto) {
        return this.httpManager.post(parameters.authServer + childPath + 'search', pageRequest).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }
}
