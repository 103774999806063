import { Storage } from '@capacitor/storage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';

import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { Account } from 'src/app/domain/yon/auth/account';
import { Role } from 'src/app/domain/yon/auth/role';

const TOKEN_KEY = 'Authorization';

@Injectable({ providedIn: 'root' })
export class AuthService {

  public helper = new JwtHelperService();

  public authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public isAuthenticated = false;
  public token: string;
  public account: Account;
  public session;

  constructor() {
    this.account = new Account();
    //this.loadToken();
  }

  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    if (token && token.value) {
      this.token = token.value;
      this.isAuthenticated = true;
      this.authenticated.next(true);
    } else {

      this.authenticated.next(false);
    }
  }

  getRole() {

    if (this.token != null) {
      const roles: Role[] = (this.helper.decodeToken(this.token)).role;

      const role: Role = roles[0];

      if (role === undefined) {
        return '';
      }

      if (role.authority === 'ROLE_ADMIN') {
        return 'admin';
      }
      else if (role.authority === 'ROLE_EDITOR') {
        return 'editor';
      }
      else if (role.authority === 'ROLE_USER') {
        return 'user';
      }
      else if (role.authority === 'ROLE_CUSTOMER') {
        return 'customer';
      }
      else if (role.authority === 'ROLE_PARTNER') {
        return 'partner';
      }
      else if (role.authority === 'ROLE_INTERN') {
        return 'intern';
      }
    }
    else {
      return null;
    }

  }

  getAllRoles(): Role[] {
    if (this.token != null) {
      const roles: Role[] = (this.helper.decodeToken(this.token)).role;

      roles.forEach(role => {
        if (role.authority === 'ROLE_ADMIN') {
          role.authority = 'admin';
        }
        else if (role.authority === 'ROLE_EDITOR') {
          role.authority = 'editor';
        }
        else if (role.authority === 'ROLE_USER') {
          role.authority = 'user';
        }
        else if (role.authority === 'ROLE_CUSTOMER') {
          role.authority = 'customer';
        }
        else if (role.authority === 'ROLE_PARTNER') {
          role.authority = 'partner';
        }
        else if (role.authority === 'ROLE_INTERN') {
          role.authority = 'intern';
        }
      });

      return roles;
    }
    else {
      return null;
    }
  }

  // getToken(): Observable<any> {
  //   return from(this.storage.get('Authorization').then(
  //     (token) => of(token)
  //   ));
  // }
}
