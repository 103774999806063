import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Demand } from 'src/app/domain/demand';
import { DemandService } from 'src/app/services/demand.service';

@Component({
  selector: 'contact-sales',
  templateUrl: './contact-sales.modal.html',
  styleUrls: ['./contact-sales.modal.scss'],
})
export class ContactSalesModal implements OnInit {

  demand: Demand;
  processing: boolean = false;

  sectors = [
    "Turizm",
    "Restoran/Cafe",
    "Sanayi",
    "Eğitim",
    "Eğlence",
    "Diğer"
  ];

  packages = [
    "Starter",
    "Gold",
    "Premium",
    "Enterprise"
  ]

  @Input("pack") pack: string;

  constructor(public modalController: ModalController,
    private toastController: ToastController,
    private demandService: DemandService) {
    this.demand = new Demand();
  }

  ngOnInit() {
    if (this.pack != undefined && this.pack != null) {
      this.demand.pack = this.pack;
    }
  }

  submit() {
    this.processing = true;
    this.demandService.create(this.demand).subscribe(
      async (res) => {
        this.processing = false;
        if (res) {
          const toast = await this.toastController.create({
            header: 'Başarılı',
            message: 'Talebiniz alındı. En kısa süre içerisinde size dönüş sağlayacağız.',
            position: 'bottom',
            color: 'success',
            duration: 5000
          });
          toast.present();
          this.modalController.dismiss();
        }
        else {
          const toast = await this.toastController.create({
            header: 'Hata',
            message: 'İleti gönderilemedi. Lütfen bilgilerinizi kontrol edip tekrar deneyin.',
            position: 'bottom',
            color: 'warning',
            duration: 5000
          });
          toast.present();
        }
      },
      async (err) => {
        this.processing = false;
        const toast = await this.toastController.create({
          header: 'Hata',
          message: 'İleti gönderilirken bir hata meydana geldi! Lütfen daha sonra tekrar deneyin.',
          position: 'top',
          color: 'danger',
          duration: 3000
        });
        toast.present();
      }
    )
  }

  selectSector(e) {
    this.demand.sector = e.target.value;
  }

  selectPack(e) {
    this.demand.pack = e.target.value;
  }
}
