import { Injectable } from '@angular/core';
import { Tour } from 'src/app/domain/experience/tour/tour';
import { SaasAddress } from 'src/app/domain/saas/saas-address';
import { SaasSubscription } from 'src/app/domain/saas/saas-subscription';
import { SaasAddressService } from 'src/app/services/saas/saas-address-service';
import { SaasSubscriptionService } from 'src/app/services/saas/saas-subscription.service';
import { AuthService } from 'src/app/services/yon/auth/auth.service';

@Injectable()
export class PanelService {
    public tours: Tour[];
    public subscriptions: SaasSubscription[];
    public saasAddresses: SaasAddress[];
    public selectedSaasSubscription: SaasSubscription;

    public selectedSaasAddress: SaasAddress;
    public isCreateAddress: boolean;
    public isPersonal: boolean;

    constructor(
        private saasAddressService: SaasAddressService,
        private subscriptionService: SaasSubscriptionService,
        private authService: AuthService
    ) {
        this.tours = new Array();
        this.subscriptions = new Array();
        this.saasAddresses = new Array();

        this.selectedSaasSubscription = new SaasSubscription();
        this.selectedSaasAddress = null;
    }

    listSubscriptionsWithOwnerId() {
        try {
            console.log('panel service subs');
            const currentAccountId = this.authService.account.id;
            this.subscriptionService.listByOwnerId(currentAccountId).subscribe(
                (res) => {
                    if (res.status) {
                        this.subscriptions = res.data;
                    }
                }
            );
        } catch {
            console.log('error occurred');
        }
    }

    listAddressesWithOwnerId() {
        try {
            console.log('panel service address');
            const currentAccountId = this.authService.account.id;
            this.saasAddressService.getByOwnerId(currentAccountId).subscribe(
                (res) => {
                    if (res.status) {
                        this.saasAddresses = res.data;
                    }
                }
            );
            console.log(this.selectedSaasAddress);
            console.log('look above');
        } catch {
            console.log('error occurred');
        }
    }
}
