import { Request } from "src/app/domain/base/request";

export class DeleteSaasAddressRequest extends Request {
    id: string;
    
  constructor(id: string) {
    super();
    this.id = id
  }

};
