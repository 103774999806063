import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subscriber } from 'rxjs';
import { AuthService } from './yon/auth/auth.service';

@Injectable()
export class UrlHelperService {
    constructor(private http: HttpClient,
        private authService: AuthService
    ) {
    }

    /**
     * @param  {string} url
     * @returns Observable
     */
    get(url: string): Observable<any> {
        return new Observable((observer: Subscriber<any>) => {
            let objectUrl: string = null;

            this.http
                .get(url, {
                    headers: this.getHeaders(),
                    responseType: 'arraybuffer'
                })
                .subscribe(m => {
                    objectUrl = URL.createObjectURL(m);
                    observer.next(objectUrl);
                });

            return () => {
                if (objectUrl) {
                    URL.revokeObjectURL(objectUrl);
                    objectUrl = null;
                }
            };
        });
    }

    /**
     * @returns HttpHeaders
     */
    getHeaders(): HttpHeaders {
        let headers = new HttpHeaders();

        headers.set('Authorization', this.authService.token);

        return headers;
    }
}