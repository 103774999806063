import { Base } from "../base";
import { SubscriptionCustomerData } from "../iyzico/subscription-customer-data";

export class SaasCustomer extends Base {
    subscriptionCustomerData: SubscriptionCustomerData;

    constructor() {
        super();
        this.subscriptionCustomerData = new SubscriptionCustomerData();
    }
}