export class Color {
    primary: string;
    primaryTint: string;
    primaryShade: string;
    primaryRgb: string;
    primaryContrast: string;
    primaryContrastRgb: string;

    secondary: string;
    secondaryTint: string;
    secondaryShade: string;
    secondaryRgb: string;
    secondaryContrast: string;
    secondaryContrastRgb: string;
}