<ion-header>
  <ion-toolbar>
    <ion-title>
      <div style="margin-inline: calc((100% - 100px) / 2); height: 7px; background: #ffffffa6; border-radius: 20px;">
      </div>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="page-content">
  <!-- <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Bölümler</ion-title>
    </ion-toolbar>
  </ion-header> -->

  <div class="content-container vertical-centralized" style="height: 100%;">
    <ion-slides #slider class="sections" [options]="slideOpts" (ionSlidesDidLoad)="ionViewDidEnter()"
      (ionSlideDidChange)="slidesChanged($event)">
      <ion-slide *ngFor="let section of sections; let i = index" class="section"
        [class.selected]="i==currentSectionIndex" (click)="close(i)">
        <ion-grid>
          <ion-row>
            <img class="section-cover bordered"
              [src]="'https://api.yon.tc/yon-media/media/image/post/'+section.coverId | secure | async" />
          </ion-row>
          <ion-row>
            <h4 class="section-name">{{section.name}}</h4>
          </ion-row>
          <div class="section-name-background"></div>
        </ion-grid>
      </ion-slide>
    </ion-slides>

  </div>
  <ion-button class="slides-navigation backward" fill="clear" [class.passive]="this.sliderIndex == 0"
    (click)="this.slider.slidePrev()">
    <ion-icon name="chevron-back-outline"></ion-icon>
  </ion-button>
  <ion-button class="slides-navigation forward" fill="clear" [class.passive]="this.sliderIndex == this.sections.length-1"
    (click)="this.slider.slideNext()">
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-button>
</ion-content>

<ion-footer>
  <ion-toolbar></ion-toolbar>
</ion-footer>