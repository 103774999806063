/* eslint-disable max-len */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/yon/auth/auth.service';
import { InterfaceService } from './services/yon/auth/interface.service';
import { ConfigurationService } from './services/yon/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  menuItems = [
    {
      name: 'Ürünler',
      link: '/products',
      children: [
        {
          name: 'productExperienceToolName',
          link: '/products/ar-now',
          icon: 'assets/logo/experience/product-exptool/brandmark.svg'
        },
        {
          name: 'spaceExperienceToolName',
          link: '/products/place-x',
          icon: 'assets/icon/space-exptool-brandmark.svg'
        },
        {
          name: 'meterName',
          link: '/products/meter',
          icon: 'assets/icon/meter-brandmark.svg'
        }
      ]
    },
    {
      name: 'solutions',
      link: '/solutions',
    },
    {
      name: 'plans',
      link: '/plans'
    },
    {
      name: 'contact',
      link: '/contact'
    }
  ];

  constructor(private config: ConfigurationService, public auth: AuthService, public router: Router, public interfaceService: InterfaceService) { }
}
