/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HttpManager {
  constructor(private http: HttpClient) {
  }

  public post(_path: string, _data: any): Observable<any> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(_path, _data,
      {
        headers: headers
      }).pipe(map(
        (data) => {
          //console.log(data);
          return data;
        },
        (err: HttpErrorResponse) => {
          console.error(err);
          return null;
        }
      ));
  }

  public postWithoutData(_path: string): Observable<any> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(_path,
      {
        headers: headers
      }).pipe(map(
        (data) => {
          //console.log(data);
          return data;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          return null;
        }
      ));
  }

  public request(formdata: FormData, url: string): Observable<HttpEvent<{}>> {
    //let headers = new HttpHeaders().set("Content-Type", "multipart/form-data").set("Accept", "application/json");

    const req = new HttpRequest('POST', url, formdata, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);
  }

  public postBlob(_path: string, _data: any): Observable<Blob> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<Blob>(_path, _data,
      {
        headers: headers,
        responseType: 'blob' as 'json'
      }).pipe(map(
        (data) => {
          //console.log(data);
          return data;
        },
        (err: HttpErrorResponse) => {
          return null;
        }
      ));
  }
}
