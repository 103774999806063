import { Type } from './type';
import { Preferences } from './preferences';
import { Department } from './department';
import { Role } from './role';
import { Seat } from './seat';
import { Base } from './base';
export class Account extends Base{
    //id: string;
    name: string;
    surname: string;
    email: string;
    phone: string;
    photo: string;
    address: string;
    username: string;
    password: string;
    title: string;
    iban: string;
    preferences: Preferences;
    roles: Role[];
    seats: Seat[];
    mainSeat: number;
    department: Department;
    type: Type;
    permanentIncome: number;
    activeIncome: number;
    accountNonExpired: boolean = true;
    credentialsNonExpired: boolean = true;
    accountNonLocked: boolean = true;
    enabled: boolean = true;

    constructor() {
        super();
        this.roles = new Array();
        this.department = new Department();
        this.type = new Type();
        this.preferences = new Preferences();
        this.seats = new Array();
    }
}