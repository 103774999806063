import { Color } from './color';
import { Type } from './type';
import { Position } from './position';
export class Organization {
    id: string;
    name: string;
    username: string;
    logo: string;
    parent: Organization;
    type: Type;
    positions: Position[];
    color: Color;
    
    constructor() {
        this.type = new Type();
        this.positions = new Array();
        this.color = new Color();
    }
}