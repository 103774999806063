import { map, filter, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/yon/auth/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
    public router: Router) { }

  /**
   * Access control in route
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {boolean}
   */

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    //Control whether the user is authenticated
    return this.authService.authenticated.pipe(
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1), // Oth
      map(
        (isAuthenticated) => {
          if (isAuthenticated) {
            return true;
          } else {
            console.log(isAuthenticated);
            this.router.navigateByUrl('/login');
            return false;
          }
          // const parsedUrl = (state.url.split('/')).slice(1);
          // const result = 'user' === this.authService.getRole() || 'admin' === this.authService.getRole();
          // if (result && parsedUrl[0] === 'auth') { return this.router.parseUrl('/panel'); }
          // else if (!result && parsedUrl[0] === 'auth') { return true; }
          // else if (!result && parsedUrl[0] === 'panel') { return this.router.parseUrl('/auth'); }
          // else { return result; }
        }
      ));
  }
}
