/* eslint-disable @typescript-eslint/dot-notation */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {Storage} from '@capacitor/storage';
import *  as  catalog from 'src/app/data/languages.json';

const ASSETS_FOLDER = 'assets/';
const FLAGS_FOLDER = 'flags/';

@Injectable()
export class LanguageService {
  public language = 'tr';

  constructor(@Inject(DOCUMENT) private document: Document
  ) {
  }

  /**
   * translates given word according to current language
   *
   * @param word
   * @returns string
   */
  translate(word: string): string {
    return catalog['default'][this.language][word];
  }

  /**
   * retrieves all available languages
   *
   *
   * @returns array
   */
  getLanguages(): [] {
    return catalog['default'].languages;
  }

  /**
   * retrieves current language code
   *
   * @returns string
   */
  getCurrentLanguageCode(): string {
    return catalog['default'].languages.find(l => l.key === this.language).code;
  }

  /**
   * retrieves current language name
   *
   * @returns string
   */
  getCurrentLanguageName(): string {
    return catalog['default'].languages.find(l => l.key === this.language).name;
  }

  /**
   * retrieves current language flag
   *
   * @returns string
   */
  getCurrentLanguageFlag(): string {
    return ASSETS_FOLDER + FLAGS_FOLDER + catalog['default'].languages.find(l => l.key === this.language).flag + '.svg';
  }

  /**
   * changes current language
   *
   * @param val
   */
  changeLanguage(val: string) {
    this.language = catalog['default'].languages.find(l => l.code === val).key;
    Storage.set({ key: 'language', value: this.language });
    this.document.documentElement.lang = this.language.split('-')[0];
  }
}
