import { SubscriptionCardData } from "src/app/domain/iyzico/subscription-card-data";
import { SubscriptionCustomerData } from "src/app/domain/iyzico/subscription-customer-data";
import { SubscriptionData } from "src/app/domain/iyzico/subscription-data";

export class SubscriptionDto {
    subscriptionData: SubscriptionData;
    subscriptionCardData: SubscriptionCardData;
    subscriptionCustomerData: SubscriptionCustomerData;
    initialStatus: string;
    accountId: string;

    constructor() {
        this.subscriptionData = new SubscriptionData();
        this.subscriptionCardData = new SubscriptionCardData();
        this.subscriptionCustomerData = new SubscriptionCustomerData();
    }
}