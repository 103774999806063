import { Request } from "src/app/domain/base/request";

export class ValidateEmailRequest extends Request {
    email: string;

    constructor(email: string) {
        super();
        this.email = email
    }

};
