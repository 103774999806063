import { Organization } from './organization';
export class Department {
    id: string;
    name: string;
    email: string;
    userCount: number;
    logo: string;
    domain: string;
    slug: string;
    organization: Organization;

    constructor() {
        this.organization = new Organization();
    }
}