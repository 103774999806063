/* eslint-disable @typescript-eslint/dot-notation */
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Storage } from '@capacitor/storage';
import { Device } from '@capacitor/device';
import { AccountService } from './auth/account.service';
import { LoginDto } from 'src/app/dto/request/login.dto';
import { LanguageService } from '../language.service';
import { ExperienceService } from '../experience/experience.service';

const TOKEN_KEY = 'Authorization';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {

  device;
  public helper = new JwtHelperService();


  constructor(@Inject(DOCUMENT) private document: Document,
    // private storage: Storage,
    private jwtHelper: JwtHelperService,
    private authService: AuthService,
    private experienceService: ExperienceService,
    private accountService: AccountService,
    private languageService: LanguageService) {
    this.getDeviceInfo();
    this.loadToken();
  }

  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    if (token && token.value) {
      this.authService.token = token.value;
      this.authService.account = this.jwtHelper.decodeToken(token.value).userDetails;
      this.authService.isAuthenticated = true;
      this.authService.authenticated.next(true);
      this.login();
    } else {
      this.login();
      //this.authService.authenticated.next(false);
    }
  }

  async getDeviceInfo() {
    this.device = await Device.getInfo();
    this.device['webViewVersion'] = undefined;
    console.log(this.device);
    // let battery = await Device.getBatteryInfo();
    // console.log("Battery: ", battery);
  }

  async setLanguage() {
    const { value } = await Storage.get({ key: 'language' });

    if (value !== null) {
      this.languageService.language = value;
    }
    else {
      const lang = await Device.getLanguageCode();
      const langKey = lang.value.split('-')[0];
      const presentLang = this.languageService.getLanguages().find(l => l['key'] === langKey)['key'];
      this.languageService.language = presentLang !== undefined ? presentLang : 'en';
    }

    this.document.documentElement.lang = this.languageService.language;
  }

  login() {
    console.log('Anonymous login request');
    const loginDto: LoginDto = new LoginDto();
    loginDto.username = '';
    loginDto.password = '';
    loginDto.appSlug = 'visetra';

    this.accountService.login(loginDto).subscribe(
      (res) => {
        console.log(res);
        this.authService.token = res;
        this.authService.account = this.jwtHelper.decodeToken(res).userDetails;
        Storage.set({ key: 'Authorization', value: res });
        this.authService.session = this.jwtHelper.decodeToken(res).session;
        this.experienceService.subject.next();
        console.log(this.jwtHelper.decodeToken(res));

      },
      (err) => {
        console.error(err);
        // this.storage.remove("Authorization");
        // this.login();
      }
    );
  }

  async getSession() {
    const { value } = await Storage.get({ key: 'Authorization' });

    if (value !== null) {
      return this.jwtHelper.decodeToken(value).session;
    }
  }

  // configureTheme() {
  //     console.log(this.authService.account.preferences)
  //     let systemDark = window.matchMedia("(prefers-color-scheme: dark)");

  //     systemDark.addListener(this.colorListener);

  //     if (this.authService.account.preferences.dark) {
  //         document.body.setAttribute('data-theme', 'dark');
  //     }
  //     else {
  //         document.body.setAttribute('data-theme', 'light');
  //     }

  // }

  // colorListener(systemInitiatedDark) {
  //     if (systemInitiatedDark.matches) {
  //         document.body.setAttribute('data-theme', 'dark');
  //     } else {
  //         document.body.setAttribute('data-theme', 'light');
  //     }
  // }

  // async getCurrentAccount(){
  //     await this.accountService.getCurrentAccount().toPromise();
  // }




}
