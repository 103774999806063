import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { QrcodeScreenModalRoutingModule } from './qrcode-screen-routing.module';

import { QrcodeScreenModal } from './qrcode-screen.modal';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    QrcodeScreenModalRoutingModule,
    QRCodeModule
  ],
  declarations: [QrcodeScreenModal]
})
export class QrcodeScreenModalModule {}
