import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable()
export class SEOService {
    public constructor(@Inject(DOCUMENT) private dom,
        private titleService: Title,
        private metaService: Meta) { }
        
    /**
     * @param  {string} newTitle
     */
    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }
    
    /**
     * @param  {string} description
     */
    public setDescription(description: string) {
        this.metaService.addTag({ name: 'description', content: description });
    }

    /**
     * @param  {string} description
     */
    public updateDescription(description: string) {
        this.metaService.updateTag({ name: 'description', content: description });
    }

    /**
     * @param  {string} keywords
     */
    public setKeywords(keywords: string) {
        this.metaService.addTag({ name: 'keywords', content: keywords });
    }

    /**
     * @param  {string} keywords
     */
    public updateKeywords(keywords: string) {
        this.metaService.updateTag({ name: 'keywords', content: keywords });
    }

    /**
     * @param  {string} robots
     */
    public setRobots(robots: string) {
        this.metaService.addTag({ name: 'robots', content: robots });
    }

    /**
     * @param  {string} robots    
     */
    public updateRobots(robots: string) {
        this.metaService.updateTag({ name: 'robots', content: robots });
    }

    /**
     * @param  {string} canonical
     */
    public setCanonical(canonical: string) {
        const canURL = canonical == undefined ? this.dom.URL : canonical;
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', canURL);
    }
    
    public setTwitterCard({ card, title, description, image, site, creator }) {
        this.metaService.addTags([
            { name: 'twitter:card', content: card },
            { name: 'twitter:title', content: title },
            { name: 'twitter:description', content: description },
            { name: 'twitter:image', content: image },
            { name: 'twitter:site', content: site },
            { name: 'twitter:creator', content: creator }
        ]);

    }

    public updateTwitterCard({ card, title, description, image, site, creator }) {
        this.metaService.updateTag({ name: 'twitter:card', content: card });
        this.metaService.updateTag({ name: 'twitter:title', content: title });
        this.metaService.updateTag({ name: 'twitter:description', content: description });
        this.metaService.updateTag({ name: 'twitter:image', content: image });
        this.metaService.updateTag({ name: 'twitter:site', content: site });
        this.metaService.updateTag({ name: 'twitter:creator', content: creator });
    }

    public setFacebookCard({ pageId, title, description, image, site, url, type, publisher, locale }) {
        this.metaService.addTags([
            { property: 'fb:page_id', content: pageId },
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            { property: 'og:image', content: image },
            { property: 'og:site_name', content: site },
            { property: 'og:url', content: url },
            { property: 'og:type', content: type },
            { property: 'og:locale', content: locale },
            { property: 'article:publisher', content: publisher },
        ]);
    }
    /**
     * @param  {} {pageId
     * @param  {} title
     * @param  {} description
     * @param  {} image
     * @param  {} site
     * @param  {} url
     * @param  {} type
     * @param  {} publisher
     * @param  {} locale}
     */
    public updateFacebookCard({ pageId, title, description, image, site, url, type, publisher, locale }) {
        this.metaService.updateTag({ property: 'fb:page_id', content: pageId });
        this.metaService.updateTag({ property: 'og:title', content: title });
        this.metaService.updateTag({ property: 'og:description', content: description });
        this.metaService.updateTag({ property: 'og:image', content: image });
        this.metaService.updateTag({ property: 'og:site_name', content: site });
        this.metaService.updateTag({ property: 'og:url', content: url });
        this.metaService.updateTag({ property: 'og:type', content: type });
        this.metaService.updateTag({ property: 'og:locale', content: locale });
        this.metaService.updateTag({ property: 'article:publisher', content: publisher });
    }
}