<ion-header>
  <ion-toolbar>
    <ion-buttons *ngIf="this.platform.width()<768" slot="start">
      <ion-button fill="clear" (click)="this.modalController.dismiss()">
        {{this.langService.translate("close")}}
      </ion-button>
    </ion-buttons>
    <ion-title>
      <div style="margin-inline: calc((100% - 100px) / 2); height: 7px; background: #ffffffa6; border-radius: 20px;">
      </div>
    </ion-title>
    <ion-buttons *ngIf="this.platform.width()>=768" slot="end">
      <ion-button fill="clear" (click)="this.modalController.dismiss()">
        <ion-icon name="close-outline" style="font-size: 45px;"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #content class="vertical-centralized">
  <div class="vertical-centralized" [class.hide]="this.contentType!='image'" style="height: 100%; width: 100%;">
    <!-- <pinch-zoom #myPinch="pinchZoom" [double-tap]="false" [limit-zoom]="2" [wheel]="false" (contextmenu)="false">
      
    </pinch-zoom> -->
    <ion-slides #slider [options]="sliderOpt" zoom style="height: 100%;">
      <ion-slide>
        <div #container class="swiper-zoom-container">

        </div>
      </ion-slide>
    </ion-slides>
  </div>
  <!-- <img *ngIf="contentType=='image'" [src]="content"> -->
  <!-- <iframe *ngIf="contentType=='video'" [src]="content"></iframe> -->
</ion-content>