import { GenericRequest } from 'src/app/dto/request/generic-request.dto';
import { map } from 'rxjs/operators';
import { HttpManager } from 'src/app/managers/http.manager';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";

import { Type } from 'src/app/domain/yon/auth/type';
import { parameters } from 'src/app/parameters/parameters';
import { PageRequestDto } from 'src/app/dto/request/page-request.dto';

const childPath = "type/";

@Injectable()
export class TypeService {

    constructor(private httpManager: HttpManager) { }

    create(type: Type): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + "create", type).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    update(type: Type): Observable<any> {
        return this.httpManager.post(parameters.authServer+ childPath + "update", type).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    delete(type: Type): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + "delete", type).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    list(): Observable<any> {
        return this.httpManager.postWithoutData(parameters.authServer + childPath + "list").pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    listByClass(genericReq: GenericRequest): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + "list-by-class", genericReq).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    listCoresByClass(genericReq: GenericRequest): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + "list-cores-by-class", genericReq).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    getById(genericReq: GenericRequest): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + "get-by-id", genericReq).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    search(pageRequest: PageRequestDto) {
        return this.httpManager.post(parameters.authServer+ childPath + "search", pageRequest).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }
}