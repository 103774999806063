import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SubscriptionCardData } from "src/app/domain/iyzico/subscription-card-data";
import { RetrieveSubscriptionListWithOwnerIdRequest } from "src/app/domain/saas/request/retrieve-subscription-list-with-owner-id-request";
import { InitializeSaasSubscriptionRequest } from "src/app/domain/saas/request/initialize-saas-subscription-request";
import { SaasAddress } from "src/app/domain/saas/saas-address";
import { SaasProduct } from "src/app/domain/saas/saas-product";
import { SaasSubscription } from "src/app/domain/saas/saas-subscription";
import { GenericRequest } from "src/app/dto/request/generic-request.dto";
import { PageRequestDto } from "src/app/dto/request/page-request.dto";
import { HttpManager } from "src/app/managers/http.manager";
import { parameters } from "src/app/parameters/parameters";
import { AuthService } from "../yon/auth/auth.service";
import { DeletePendingSaasSubscriptionRequest } from "src/app/domain/saas/request/delete-pending-saas-subscription-request";
import { CancelSaasSubscriptionRequest } from "src/app/domain/saas/request/saas-subscription/cancel-saas-subscription-request";

const childPath = 'subscription/';

@Injectable()
export class SaasSubscriptionService {
    constructor(
        private httpManager: HttpManager,
        private authService: AuthService) {
    }

    create(product: SaasProduct): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "create", product).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    demoInitSubscription(
        cardData: SubscriptionCardData,
        billingAddress: SaasAddress,
        saasSubscription: SaasSubscription
        ): Observable<any> {

        return this.httpManager.post(parameters.server + childPath + "initialize-subscription-demo", new InitializeSaasSubscriptionRequest(cardData,billingAddress,saasSubscription)).pipe(
            map(
                (res) => {
                    return res.data
                }
            )
        );
    }

    deletePending(
        id: string
    ): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "delete-pending", new DeletePendingSaasSubscriptionRequest(id)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        )
       
    }

    cancel(
        referenceCode: string
    ): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "cancel", new CancelSaasSubscriptionRequest(referenceCode)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        )
       
    }

    

    // getSubscriptionsByOwnerId(
    //     onwerId : string
    // ): Observable <any> {
    //     return this.httpManager.post(parameters.server + childPath + "get-subscriptions-by-owner-id", product).pipe(
    //         map(
    //             (status) => {
    //                 if (status != null) {
    //                     return status;
    //                 }
    //                 else {
    //                     return null;
    //                 }
    //             }
    //         )
    //     );
    // }

    // update(tour: Tour): Observable<any> {
    //       return this.httpManager.post(parameters.server + childPath + "update", tour).pipe(
    //         map(
    //             (status) => {
    //                 if (status != null) {
    //                     return status;
    //                 }
    //                 else {
    //                     return null;
    //                 }
    //             }
    //         )
    //     );
    // }

    // delete(tour: Tour): Observable<any> {
    //     return this.httpManager.post(parameters.server + childPath + "delete", tour).pipe(
    //         map(
    //             (status) => {
    //                 if (status != null) {
    //                     return status;
    //                 }
    //                 else {
    //                     return null;
    //                 }
    //             }
    //         )
    //     );
    // }

    search(pageRequest: PageRequestDto) {
        return this.httpManager.post(parameters.server + childPath + "search", pageRequest).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    // list(pageRequest: PageRequestDto): Observable<any> {
    //     return this.httpManager.post(parameters.server + childPath + "list", pageRequest).pipe(
    //         map(
    //             (res) => {
    //                 if (res != null) {
    //                     return res;
    //                 }
    //                 else {
    //                     return null;
    //                 }
    //             }
    //         )
    //     );
    // }

    // /**
    //  * list subscriptions of the current account. 
    //  * @returns Observable
    //  */
    // listByOwnerId(): Observable<any> {
    //     return this.httpManager.postWithoutData(parameters.server + childPath + "list").pipe(
    //         map(
    //             (response: Response) => {
    //                 return response;
    //             }
    //         )
    //     );
    // }

    /**
 * list subscriptions of the current account. 
 * @returns Observable
 */
    listByOwnerId(accountId: string): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "list-by-owner-id", new RetrieveSubscriptionListWithOwnerIdRequest(accountId)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        );
    }

    getById(genericReq: GenericRequest): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "get-by-id", genericReq).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }
}
