import { Directive, HostListener } from '@angular/core';
import { InterfaceService } from 'src/app/services/yon/auth/interface.service';

@Directive({
    selector: '[scrollListen]'
})
export class ScrollDirective {

    private lastY = 0;

    constructor(private interfaceService: InterfaceService) {

    }

    @HostListener('ionScroll', ['$event']) onContentScroll($event: any) {
        // if ($event.detail.scrollTop > this.lastY) {
        //     this.interfaceService.headerSubject.next($event.detail.scrollTop)
        //     // this.domCtrl.write(() => {
        //     //     this.renderer.setStyle(this.header, 'margin-top', `-${ this.header.clientHeight }px`);
        //     // });
        // } else {
        //     // this.domCtrl.write(() => {
        //     //     this.renderer.setStyle(this.header, 'margin-top', '0');
        //     // });
        // }

        //this.lastY = $event.detail.scrollTop;

        this.interfaceService.headerSubject.next($event.detail.scrollTop)
        
    }
}
