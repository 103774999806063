<ion-header [translucent]="true">
    <ion-toolbar>
        <ion-title *ngIf="isCreate">Adres Ekle</ion-title>
        <ion-title *ngIf="!isCreate">Adresi Düzenle</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="page-content" [fullscreen]="true">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title *ngIf="isCreate">Adres Ekle</ion-title>
            <ion-title *ngIf="!isCreate">{{organizationalAddressForm.controls.addressName}}</ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-segment (ionChange)="segmentChanged($event)" mode="md" scrollable="true" [value]="segmentValue"
        *ngIf="isCreate">
        <ion-segment-button value="company">
            <ion-label>Şirket</ion-label>
        </ion-segment-button>
        <ion-segment-button value="personal">
            <ion-label>Kişisel</ion-label>
        </ion-segment-button>
    </ion-segment>

    <h1>{{languageService.translate('billingInformation')}}</h1>

    <form *ngIf="segmentValue==='company'" [formGroup]='organizationalAddressForm' (ngSubmit)="submit()">

        <ion-item for="addressName"
            [ngClass]="organizationalAddressForm.value.addressName === null ? 'at-beginning':''">
            <ion-label position="floating">Adres Adı *</ion-label>
            <ion-input name="addressName" formControlName="addressName" inputmode="text" autocapitalize="words"
                placeholder="Adres Adı">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.addressName.touched && !organizationalAddressForm.controls.addressName.dirty">
            <div *ngIf="organizationalAddressForm.controls.addressName.errors['required']" class="alert alert-danger">
                Adres adı
                zorunlu.
            </div>
        </div>

        <ion-item for="organizationTitle"
            [ngClass]="organizationalAddressForm.value.organizationTitle === null ? 'at-beginning':''">
            <ion-label position="floating">Kurum Adı *</ion-label>
            <ion-input name="organizationTitle" formControlName="organizationTitle" inputmode="text"
                autocapitalize="words" placeholder="Kurum Adı">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.organizationTitle.touched && !organizationalAddressForm.controls.organizationTitle.dirty">
            <div *ngIf="organizationalAddressForm.controls.organizationTitle.errors['required']"
                class="alert alert-danger">Kurum
                adı zorunlu.
            </div>
        </div>

        <ion-item for="taxNo"
            [ngClass]="organizationalAddressForm.value.organizationTitle === null ? 'at-beginning':''">
            <ion-label position="floating">Vergi Numarası *</ion-label>
            <ion-input name="taxNo" formControlName="taxNo" inputmode="decimal" type="text" autocapitalize="words"
                placeholder="Vergi Numarası">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.taxNo.touched && !organizationalAddressForm.controls.taxNo.dirty && segmentValue==='company'">
            <div *ngIf="organizationalAddressForm.controls.taxNo.errors['required']" class="alert alert-danger">Vergi
                numarası
                zorunlu.
            </div>
        </div>

        <ion-item for="taxOffice" [ngClass]="organizationalAddressForm.value.taxOffice === null ? 'at-beginning':''">
            <ion-label position="floating">Vergi Dairesi *</ion-label>
            <ion-input name="taxOffice" formControlName="taxOffice" inputmode="text" autocapitalize="words"
                placeholder="Vergi Dairesi">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.taxOffice.touched && !organizationalAddressForm.controls.taxOffice.dirty">
            <div *ngIf="organizationalAddressForm.controls.taxOffice.errors['required']" class="alert alert-danger">
                Vergi dairesi
                adı zorunlu.
            </div>
        </div>

        <ion-item for="name" [ngClass]="organizationalAddressForm.value.name === null ? 'at-beginning':''">
            <ion-label position="floating">Ad *</ion-label>
            <ion-input name="name" formControlName="name" inputmode="text" autocapitalize="words"
                (keypress)="allowCharOnly($event)" placeholder="Ad">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.name.touched && !organizationalAddressForm.controls.name.dirty">
            <div *ngIf="organizationalAddressForm.controls.name.errors['required']" class="alert alert-danger">Alıcı adı
                zorunlu.
            </div>
            <div *ngIf="organizationalAddressForm.controls.name.errors['pattern']" class="alert alert-danger">Geçersiz
                alıcı adı.
            </div>
        </div>

        <ion-item for="surname" [ngClass]="organizationalAddressForm.value.surname === null ? 'at-beginning':''">
            <ion-label position="floating">Soyad *</ion-label>
            <ion-input name="surname" formControlName="surname" inputmode="text" autocapitalize="words"
                (keypress)="allowCharOnly($event)" placeholder="Soyad">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.surname.touched && !organizationalAddressForm.controls.surname.dirty">
            <div *ngIf="organizationalAddressForm.controls.surname.errors['required']" class="alert alert-danger">Alıcı
                soyadı
                zorunlu.
            </div>
            <div *ngIf="organizationalAddressForm.controls.surname.errors['pattern']" class="alert alert-danger">
                Geçersiz alıcı soyadı.
            </div>
        </div>

        <ion-item for="gsmNumber" [ngClass]="organizationalAddressForm.value.gsmNumber === null ? 'at-beginning':''">
            <ion-label position="floating">İletişim Numarası *</ion-label>
            <ion-input name="gsmNumber" formControlName="gsmNumber" inputmode="text" type="text"
                placeholder="Telefon Numarası">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.gsmNumber.touched && !organizationalAddressForm.controls.gsmNumber.dirty">
            <div *ngIf="organizationalAddressForm.controls.gsmNumber.errors['required']" class="alert alert-danger">
                Telefon
                numarası zorunlu.
            </div>
        </div>

        <ion-item for="country" [ngClass]="organizationalAddressForm.value.country === null ? 'at-beginning':''">
            <ion-label position="floating">Ülke *</ion-label>
            <ion-input name="country" formControlName="country" inputmode="text" (click)="allowCharOnly($event)"
                placeholder="Ülke">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.country.touched && !organizationalAddressForm.controls.country.dirty">
            <div *ngIf="organizationalAddressForm.controls.country.errors['required']" class="alert alert-danger">Ülke
                adı
                zorunlu.
            </div>
        </div>

        <ion-item for="state" [ngClass]="organizationalAddressForm.value.state === null ? 'at-beginning':''">
            <ion-label position="floating">Eyalet</ion-label>
            <ion-input name="state" formControlName="state" inputmode="text" autocapitalize="sentences"
                (click)="allowCharOnly($event)" placeholder="Eyalet">
            </ion-input>
        </ion-item>

        <!--        <div class="alert alert-danger"-->
        <!--             *ngIf="organizationalAddressForm.controls.state.touched && !organizationalAddressForm.controls.state.dirty">-->
        <!--            <div *ngIf="organizationalAddressForm.controls.state.errors['required']" class="alert alert-danger">Şehir-->
        <!--                adı-->
        <!--                zorunlu.-->
        <!--            </div>-->
        <!--        </div>-->
        <ion-item for="city" [ngClass]="organizationalAddressForm.value.city === null ? 'at-beginning':''">
            <ion-label position="floating">Şehir *</ion-label>
            <ion-input name="city" formControlName="city" inputmode="text" autocapitalize="sentences"
                (click)="allowCharOnly($event)" placeholder="Şehir">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.city.touched && !organizationalAddressForm.controls.city.dirty">
            <div *ngIf="organizationalAddressForm.controls.city.errors['required']" class="alert alert-danger">Şehir adı
                zorunlu.
            </div>
        </div>

        <ion-item for="district" [ngClass]="organizationalAddressForm.value.district === null ? 'at-beginning':''">
            <ion-label position="floating">İlçe *</ion-label>
            <ion-input name="district" formControlName="district" inputmode="text" placeholder="İlçe">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.district.touched && !organizationalAddressForm.controls.district.dirty">
            <div *ngIf="organizationalAddressForm.controls.district.errors['required']" class="alert alert-danger">
                Mahalle adı
                zorunlu.
            </div>
        </div>

        <ion-item for="address" [ngClass]="organizationalAddressForm.value.address === null ? 'at-beginning':''">
            <ion-label position="floating">Adres *</ion-label>
            <ion-input name="address" formControlName="address" inputmode="text" autocapitalize="sentences"
                placeholder="Adres">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.address.touched && !organizationalAddressForm.controls.address.dirty">
            <div *ngIf="organizationalAddressForm.controls.address.errors['required']" class="alert alert-danger">Adres
                zorunlu.
            </div>
        </div>

        <ion-item for="zipCode" [ngClass]="organizationalAddressForm.value.zipCode === null ? 'at-beginning':''">
            <ion-label position="floating">Posta Kodu *</ion-label>
            <ion-input name="zipCode" formControlName="zipCode" inputmode="text" placeholder="Posta Kodu">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="organizationalAddressForm.controls.zipCode.touched && !organizationalAddressForm.controls.zipCode.dirty">
            <div *ngIf="organizationalAddressForm.controls.zipCode.errors['required']" class="alert alert-danger">Posta
                kodu
                zorunlu.
            </div>
        </div>

        <ion-button *ngIf="isCreate && segmentValue==='company'" fill="clear" (click)="submitForm()"
            [disabled]="!organizationalAddressForm.valid">
            Adresi Oluştur
        </ion-button>
        <ion-button *ngIf="!isCreate" fill="clear" (click)="updateForm()" [disabled]="!organizationalAddressForm.valid">
            Adresi Güncelle
        </ion-button>
    </form>

    <form *ngIf="segmentValue==='personal'" [formGroup]='personalAddressForm' (ngSubmit)="submit()">

        <ion-item for="addressName" [ngClass]="personalAddressForm.value.addressName === null ? 'at-beginning':''">
            <ion-label position="floating">Adres Adı *</ion-label>
            <ion-input name="addressName" formControlName="addressName" inputmode="text" autocapitalize="words"
                placeholder="Adres Adı">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="personalAddressForm.controls.addressName.touched && !personalAddressForm.controls.addressName.dirty">
            <div *ngIf="personalAddressForm.controls.addressName.errors['required']" class="alert alert-danger">
                Adres adı
                zorunlu.
            </div>
        </div>

        <ion-item for="name" [ngClass]="personalAddressForm.value.name === null ? 'at-beginning':''">
            <ion-label position="floating">Ad *</ion-label>
            <ion-input name="name" formControlName="name" inputmode="text" autocapitalize="words"
                (keypress)="allowCharOnly($event)" placeholder="Ad">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="personalAddressForm.controls.name.touched && !personalAddressForm.controls.name.dirty">
            <div *ngIf="personalAddressForm.controls.name.errors['required']" class="alert alert-danger">Alıcı adı
                zorunlu.
            </div>
            <div *ngIf="personalAddressForm.controls.name.errors['pattern']" class="alert alert-danger">Geçersiz
                alıcı adı.
            </div>
        </div>

        <ion-item for="surname" [ngClass]="personalAddressForm.value.surname === null ? 'at-beginning':''">
            <ion-label position="floating">Soyad *</ion-label>
            <ion-input name="surname" formControlName="surname" inputmode="text" autocapitalize="words"
                (keypress)="allowCharOnly($event)" placeholder="Soyad">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="personalAddressForm.controls.surname.touched && !personalAddressForm.controls.surname.dirty">
            <div *ngIf="personalAddressForm.controls.surname.errors['required']" class="alert alert-danger">Alıcı
                soyadı
                zorunlu.
            </div>
            <div *ngIf="organizationalAddressForm.controls.surname.errors['pattern']" class="alert alert-danger">
                Geçersiz alıcı soyadı.
            </div>
        </div>

        <ion-item for="identityNumber" [ngClass]="personalAddressForm.value.identityNumber=== null ? 'at-beginning':''">
            <ion-label position="floating">Kimlik Numarası *</ion-label>
            <ion-input name="identityNumber" formControlName="identityNumber" inputmode="decimal" type="text"
                autocapitalize="words" (keypress)="allowNumbersOnly($event)" placeholder="Kimlik Numarası">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="personalAddressForm.controls.identityNumber.touched && !personalAddressForm.controls.identityNumber.dirty">
            <div *ngIf="personalAddressForm.controls.identityNumber.errors['required']" class="alert alert-danger">
                Kimlik
                numarası zorunlu.
            </div>
            <div *ngIf="personalAddressForm.controls.identityNumber.errors['pattern']" class="alert alert-danger">
                Kimlik
                numarası harf içeremez.
            </div>
        </div>

        <ion-item for="gsmNumber" [ngClass]="personalAddressForm.value.gsmNumber === null ? 'at-beginning':''">
            <ion-label position="floating">İletişim Numarası *</ion-label>
            <ion-input name="gsmNumber" formControlName="gsmNumber" inputmode="tel" type="text"
                (keypress)="allowPhoneNumberOnly($event)" placeholder="Telefon Numarası">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="personalAddressForm.controls.gsmNumber.touched && !personalAddressForm.controls.gsmNumber.dirty">
            <div *ngIf="organizationalAddressForm.controls.gsmNumber.errors['required']" class="alert alert-danger">
                Telefon
                numarası zorunlu.
            </div>
        </div>

        <ion-item for="country" [ngClass]="personalAddressForm.value.country === null ? 'at-beginning':''">
            <ion-label position="floating">Ülke *</ion-label>
            <ion-input name="country" formControlName="country" inputmode="text" (keypress)="allowCharOnly($event)"
                placeholder="Ülke">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="personalAddressForm.controls.country.touched && !personalAddressForm.controls.country.dirty">
            <div *ngIf="personalAddressForm.controls.country.errors['required']" class="alert alert-danger">Ülke
                adı
                zorunlu.
            </div>
        </div>

        <ion-item for="state" [ngClass]="personalAddressForm.value.state === null ? 'at-beginning':''">
            <ion-label position="floating">Eyalet</ion-label>
            <ion-input name="state" formControlName="state" inputmode="text" autocapitalize="sentences"
                placeholder="Eyalet">
            </ion-input>
        </ion-item>
        <!--        <div class="alert alert-danger"-->
        <!--             *ngIf="personalAddressForm.controls.state.touched && !personalAddressForm.controls.state.dirty">-->
        <!--            <div *ngIf="personalAddressForm.controls.state.errors['maxLength']" class="alert alert-danger">Eyalet ismi o kadar uzun olamaz.-->
        <!--            </div>-->
        <!--        </div>-->
        <ion-item for="city" [ngClass]="personalAddressForm.value.city === null ? 'at-beginning':''">
            <ion-label position="floating">Şehir *</ion-label>
            <ion-input name="city" formControlName="city" inputmode="text" autocapitalize="sentences"
                (keypress)="allowCharOnly($event)" placeholder="Şehir">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="personalAddressForm.controls.city.touched && !personalAddressForm.controls.city.dirty">
            <div *ngIf="personalAddressForm.controls.city.errors['required']" class="alert alert-danger">Şehir adı
                zorunlu.
            </div>
        </div>

        <ion-item for="district" [ngClass]="personalAddressForm.value.district === null ? 'at-beginning':''">
            <ion-label position="floating">İlçe *</ion-label>
            <ion-input name="district" formControlName="district" inputmode="text" placeholder="İlçe">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="personalAddressForm.controls.district.touched && !personalAddressForm.controls.district.dirty">
            <div *ngIf="personalAddressForm.controls.district.errors['required']" class="alert alert-danger">
                Mahalle adı
                zorunlu.
            </div>
        </div>

        <ion-item for="address" [ngClass]="personalAddressForm.value.address === null ? 'at-beginning':''">
            <ion-label position="floating">Adres *</ion-label>
            <ion-input name="address" formControlName="address" inputmode="text" autocapitalize="sentences"
                placeholder="Adres">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="personalAddressForm.controls.address.touched && !personalAddressForm.controls.address.dirty">
            <div *ngIf="personalAddressForm.controls.address.errors['required']" class="alert alert-danger">Adres
                zorunlu.
            </div>
        </div>

        <ion-item for="zipCode" [ngClass]="personalAddressForm.value.zipCode === null ? 'at-beginning':''">
            <ion-label position="floating">Posta Kodu *</ion-label>
            <ion-input name="zipCode" formControlName="zipCode" inputmode="text" placeholder="Posta Kodu">
            </ion-input>
        </ion-item>
        <div class="alert alert-danger"
            *ngIf="personalAddressForm.controls.zipCode.touched && !personalAddressForm.controls.zipCode.dirty">
            <div *ngIf="personalAddressForm.controls.zipCode.errors['required']" class="alert alert-danger">Posta
                kodu
                zorunlu.
            </div>
        </div>

        <ion-button *ngIf="isCreate" fill="clear" (click)="submitForm()" [disabled]="!personalAddressForm.valid">
            Adresi Oluştur
        </ion-button>
        <ion-button *ngIf="!isCreate" fill="clear" (click)="updateForm()" [disabled]="!personalAddressForm.valid">
            Adresi Güncelle
        </ion-button>
    </form>

</ion-content>