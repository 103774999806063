import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../services/yon/configuration.service';
/** Simulate server replying to file upload request */
@Injectable()
export class DeviceInterceptor implements HttpInterceptor {

    constructor(private configService: ConfigurationService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(
            req.clone({
                headers: req.headers.append('Device', JSON.stringify(this.configService.device))
            })
        );
    }
}
