import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpManager } from 'src/app/managers/http.manager';
import { Item } from 'src/app/domain/yon/auth/item';
import { parameters } from 'src/app/parameters/parameters';
import { PageRequestDto } from 'src/app/dto/request/page-request.dto';
import { SearchDto } from 'src/app/dto/request/search.dto';
import { GenericRequest } from 'src/app/dto/request/generic-request.dto';


const childPath = "item/";


@Injectable()
export class ItemService {
    
    constructor(private httpManager: HttpManager) {
    }

    create(item: Item): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + "create", item).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    update(item: Item): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + "update", item).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    delete(item: Item): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + "delete", item).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    search(pageRequest: PageRequestDto) {
        return this.httpManager.post(parameters.authServer + childPath + "search", pageRequest).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    searchByType(searchDto: SearchDto) {
        return this.httpManager.post(parameters.authServer + childPath + "search-by-type", searchDto).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    getById(genericReq: GenericRequest): Observable<any> {//Add Item Id to Generic Request's data attribute
        return this.httpManager.post(parameters.authServer + childPath + "get-by-id", genericReq).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    getByPropertyIdAndQualifierIdyId(searchDto: SearchDto): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + "get-by- property-id-and-qualifier-id", searchDto).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    list(pageRequest: PageRequestDto): Observable<any> {
        return this.httpManager.post(parameters.authServer + childPath + "list", pageRequest).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }
}