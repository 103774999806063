import { Injectable } from "@angular/core";
import { HttpManager } from '../managers/http.manager';
import { Contact } from '../domain/contact';
import { Observable } from 'rxjs';
import { parameters } from '../parameters/parameters';
import { PageRequestDto } from '../dto/request/page-request.dto';
import { map } from 'rxjs/operators';

const childPath = "contact/";

@Injectable()
export class ContactService {

    constructor(private httpManager: HttpManager) {
    }
    
    /**
     * @param  {Contact} contact
     * @returns Observable
     */
    create(contact: Contact): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "create", contact).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    /**
     * @param  {Contact} contact
     * @returns Observable
     */
    update(contact: Contact): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "update", contact).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    /**
     * @param  {Contact} contact
     * @returns Observable
     */
    delete(contact: Contact): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "delete", contact).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    /**
     * @param  {PageRequestDto} pageRequest
     * @returns Observable
     */
    list(pageRequest: PageRequestDto): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "list", pageRequest).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }
}