import { Request } from "../../base/request";

export class DeletePendingSaasSubscriptionRequest extends Request {

    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }


}