import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HotspotPreviewModalRoutingModule } from './hotspot-preview-routing.module';

import { HotspotPreviewModal } from './hotspot-preview.modal';
// import { PinchZoomModule } from 'ngx-pinch-zoom';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HotspotPreviewModalRoutingModule,
    // PinchZoomModule
  ],
  declarations: [HotspotPreviewModal]
})
export class HotspotPreviewModalModule {}
