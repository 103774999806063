import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-address',
  templateUrl: './address.modal.html',
  styleUrls: ['./address.modal.scss'],
})
export class AddressModal implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
