import { PageRequest } from "src/app/domain/base/page-request";
import { Request } from "src/app/domain/base/request";

export class RetrieveSaasAddressListWithOwnerIdRequest extends Request {
    ownerId: string;

    constructor(ownerId: string) {
        super();
        this.ownerId = ownerId
    }

};
