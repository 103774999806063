import { Base } from "../base";
import { SubscriptionPricingPlanData } from "../iyzico/subscription-pricing-plan-data";

export class SaasPricingPlan extends Base {
    subscriptionPricingPlanData: SubscriptionPricingPlanData;
    saasProductId: string;
    commitment: number; //MONTH
    basePrice: number;
    index: number;

    constructor() {
        super();
        this.subscriptionPricingPlanData = new SubscriptionPricingPlanData();
    }
}