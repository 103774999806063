export const parameters = {
  //server: "http://185.184.25.42:8798/",
  //server: "http://visetra.api.yon.tc:8080/",
  //server: "https://visetra.api.yon.tc:8443/",
  //server: "http://185.184.25.42:8080/visetra-api/",
  //server: "http://localhost:8798/",
  //server: "http://192.168.0.10:8798/",
  //server: "http://192.168.1.198:8798/",
  //server: "https://185.184.25.42:8443/visetra-api/",
  server: 'https://api.yon.tc/visetra-api/',
  //server: "http://192.168.1.117:8798/",
  //mediaServer: "http://185.184.25.42:8898/",
  //mediaServer: "http://media.api.yon.tc:8080/",
  //mediaServer: "https://media.api.yon.tc:8443/",
  //mediaServer: "http://185.184.25.42:8080/yon-media/",
  //mediaServer: "https://185.184.25.42:8443/yon-media/",
  mediaServer: 'https://api.yon.tc/yon-media/',
  //mediaServer: "http://localhost:8898/",
  //authServer: "http://localhost:8184/",
  //authServer: "http://192.168.1.198:8184/"
  //authServer: "http://185.184.25.42:8878/"
  //authServer: "http://auth.api.yon.tc:8080/"
  authServer: 'https://api.yon.tc/yon-auth/'
  //authServer: "https://185.184.25.42:8443/yon-auth/"
};
