import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
/** Simulate server replying to file upload request */
@Injectable()
export class AppSlugInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
        req.clone({
          headers: req.headers.append('Application-Slug', 'visetra')
        })
      );
  }
}