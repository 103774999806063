<ion-content>
  <ion-button class="modal-closer" fill="clear" (click)="this.modalController.dismiss();">
    <ion-icon slot="icon-only" name="close-outline"></ion-icon>
  </ion-button>
  <form #demandForm="ngForm" [ngClass]="{'submitting' : processing}" (ngSubmit)="submit()">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <div class="hgroup medium centered">
            <h3 class="hgroup__heading">Teklif İsteyin</h3>
            <p class="hgroup__desc">Aşağıda bulunan alanları iletişim bilgilerinizle birlikte doldurup bize gönderin.
              Uzmanlarımız en kısa süre içerisinde size ulaşsınlar.</p>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
          <ion-item [ngClass]="demand.name==null ? 'at-beginning':''">
            <ion-label position="stacked">Ad</ion-label>
            <ion-input name="name" [(ngModel)]="demand.name" required="true" inputmode="text"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
          <ion-item [ngClass]="demand.surname==null ? 'at-beginning':''">
            <ion-label position="stacked">Soyad</ion-label>
            <ion-input name="surname" [(ngModel)]="demand.surname" required="true" inputmode="text"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item [ngClass]="demand.email==null ? 'at-beginning':''">
            <ion-label position="stacked">Eposta</ion-label>
            <ion-input name="email" [(ngModel)]="demand.email" required="true" inputmode="email"
              pattern="[a-z0-9._%+-]{1,30}@[a-z0-9.-]{1,30}.[a-z]{2,4}"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item [ngClass]="demand.phone==null ? 'at-beginning':''">
            <ion-label position="stacked">Telefon</ion-label>
            <ion-input name="phone" [(ngModel)]="demand.phone" inputmode="tel" pattern="[0-9]{10}"
              placeholder="5xx-xxx-xx-xx" required="true"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item [ngClass]="demand.company==null ? 'at-beginning':''">
            <ion-label position="stacked">Kuruluş</ion-label>
            <ion-input name="company" [(ngModel)]="demand.company" inputmode="text" required="true"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item [ngClass]="demand.sector==null ? 'at-beginning':'item-has-value'">
            <ion-label position="floating">Sektör</ion-label>
            <ion-select placeholder="Sektör Seçiniz" cancelText="Vazgeç" okText="Seç" selectedText="{{demand.sector}}"
              (ionChange)="selectSector($event)" [ngClass]="{'item-has-value':demand.sector!=null}"
              interface="action-sheet" required="true">
              <ion-select-option *ngFor="let sector of sectors" [value]="sector">
                {{sector}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item [ngClass]="demand.pack==null ? 'at-beginning':'item-has-value'">
            <ion-label position="floating">Paket</ion-label>
            <ion-select placeholder="Paket Seçiniz" cancelText="Vazgeç" okText="Seç" selectedText="{{demand.pack}}"
              (ionChange)="selectPack($event)" [ngClass]="{'item-has-value':demand.pack!=null}" interface="action-sheet"
              required="true">
              <ion-select-option *ngFor="let pack of packages" [value]="pack">
                {{pack}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button [disabled]="!demandForm.form.valid || demand.sector==null || demand.pack==null" expand="block"
            type="submit" color="primary">Gönder
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div class="processing" *ngIf="processing">
      <ion-icon class="spinner" slot="start" src="assets/icon/brandmark.svg"></ion-icon>
    </div>
  </form>
</ion-content>