import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { GenericRequest } from './../../../dto/request/generic-request.dto';

import { HttpClient, HttpEvent } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';

import { HttpManager } from 'src/app/managers/http.manager';
import { parameters } from 'src/app/parameters/parameters';

const childPath = "file/";

@Injectable()
export class FileService {

    constructor(private httpManager: HttpManager,
        private http: HttpClient,
        private sanitizer: DomSanitizer) {

    }

    create(blob: Blob, parentId: string): Observable<HttpEvent<{}>> {
        let formData: FormData = new FormData();

        formData.append('mfile', blob);
        console.log(formData)
        return this.httpManager.request(formData, parameters.mediaServer + childPath + "create/" + parentId).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );

        //return this.httpManager.request(formData, environment.mediaServer + childPath + "create");
    }

    update(file: File): Observable<any> {
        return this.httpManager.post(parameters.mediaServer + childPath + "update", file).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    delete(file: File): Observable<any> { //Add Account Id to Generic Request's data attribute
        return this.httpManager.post(parameters.mediaServer + childPath + "delete", file).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    /**
     * Gets File by id
     * @param genericReq
     * @returns Blob File
     */
    getFile(genericReq: GenericRequest): Observable<any> {
        return this.httpManager.postBlob(parameters.mediaServer + childPath + "get-file", genericReq).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    /**
     * Gets File as Image by id
     * @param genericReq
     * @returns Blob File
     */
    getImage(genericReq: GenericRequest): Observable<any> {
        return this.httpManager.postBlob(parameters.mediaServer + childPath + "get-image", genericReq).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    /**
     * Gets Thumbnail of Image by id
     * @param genericReq
     * @returns Blob File
     */
    getThumbFile(genericReq: GenericRequest): Observable<any> {
        return this.httpManager.postBlob(parameters.mediaServer + childPath + "get-thumb", genericReq).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    /**
    * Gets Post of Image by id
    * @param genericReq
    * @returns Blob File
    */
    getPostFile(genericReq: GenericRequest): Observable<any> {
        return this.httpManager.postBlob(parameters.mediaServer + childPath + "get-post", genericReq).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    /**
     * @param  {string} id
     * @returns Observable
     */
    getVideoUrl(id: string): Observable<SafeUrl> {
        return this.http
            .get(parameters.mediaServer + "video/stream/mp4/" + id, { responseType: 'blob' })
            .pipe(map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
    }
}