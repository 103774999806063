<ion-header>
  <ion-toolbar>
    <ion-title>
      <div style="margin-inline: calc((100% - 100px) / 2); height: 7px; background: #ffffffa6; border-radius: 20px;">
      </div>
    </ion-title>
  </ion-toolbar>
</ion-header>
<div class="qr-code-screen vertical-centralized horizontal-centeralized">
  <div>
    <qrcode [qrdata]="url" [elementType]="'svg'" [colorDark]="'#e94a29'" [colorLight]="'#00000000'" [width]="256"
      [errorCorrectionLevel]="'M'"></qrcode>
    <div class="qr-text">
      {{languageService.translate("qrCodeExplanation")}}
    </div>
  </div>
</div>

<div class="bottom-modal-closer-container horizontal-centeralized">
  <ion-button class="bottom-modal-closer" fill="outline" (click)="this.modalController.dismiss();">
    <ion-icon slot="icon-only" name="close-outline"></ion-icon>
  </ion-button>
</div>