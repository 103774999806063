import { Injectable } from "@angular/core";
import { HttpManager } from '../managers/http.manager';
import { Demand } from '../domain/demand';
import { Observable } from 'rxjs';
import { parameters } from '../parameters/parameters';
import { PageRequestDto } from '../dto/request/page-request.dto';
import { map } from 'rxjs/operators';

const childPath = "demand/";

@Injectable()
export class DemandService { 
    
    constructor(private httpManager: HttpManager) {
    }

    /**
     * @param  {Demand} demand
     * @returns Observable
     */
    create(demand: Demand): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "create", demand).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }
    
    /**
     * @param  {Demand} demand
     * @returns Observable
     */
    update(demand: Demand): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "update", demand).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }
    
    /**
     * @param  {Demand} demand
     * @returns Observable
     */
    delete(demand: Demand): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "delete", demand).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }
    
    /**
     * @param  {PageRequestDto} pageRequest
     * @returns Observable
     */
    list(pageRequest: PageRequestDto): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "list", pageRequest).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }
}