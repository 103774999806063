import { HttpManager } from './../../../managers/http.manager';

import { map } from 'rxjs/operators';


import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { GenericRequest } from 'src/app/dto/request/generic-request.dto';
import { Directory } from 'src/app/domain/yon/media/directory';
import { parameters } from 'src/app/parameters/parameters';

const childPath = "directory/";

@Injectable()
export class DirectoryService {

    constructor(private httpManager: HttpManager) {

    }

    create(directory: Directory): Observable<any> {
        return this.httpManager.post(parameters.mediaServer + childPath + "create", directory).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    update(directory: Directory): Observable<any> {
        return this.httpManager.post(parameters.mediaServer + childPath + "update", directory).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    delete(directory: Directory): Observable<any> { //Add Account Id to Generic Request's data attribute
        return this.httpManager.post(parameters.mediaServer + childPath + "delete", directory).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    list(): Observable<any> {
        return this.httpManager.postWithoutData(parameters.mediaServer + childPath + "list").pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    getRootMediaList(): Observable<any> {
        return this.httpManager.postWithoutData(parameters.mediaServer + childPath + "get-root-media-list").pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    /**
     * Gets Role by id
     * @param genericReq
     * @returns Directory
     */
    getMediaListById(genericReq: GenericRequest): Observable<any> {
        return this.httpManager.post(parameters.mediaServer + childPath + "get-media-list-by-id", genericReq).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    // updateLimitations(limitDto: LimitDto): Observable<any> {
    //     return this.httpManager.post(childPath + "update-limitations", limitDto).pipe(
    //         map(
    //             (status) => {
    //                 if (status != null) {
    //                     return status;
    //                 }
    //                 else {
    //                     return null;
    //                 }
    //             }
    //         )
    //     );
    // }

    getRoot() {
        return this.httpManager.postWithoutData(parameters.mediaServer + childPath + "get-root").pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        )
    }
}