import { Base } from '../yon/auth/base';

export class SaasAddress extends Base {

    addressName: string;
    name: string;
    surname: string;
    contactName: string;
    gsmNo: string;
    email: string;
    identityNo: string;
    taxNo: string;
    taxOffice: string;
    organizationTitle: string;
    country: string;
    state: string;
    city: string;
    district: string;
    address: string;
    zipCode: string;
    personal: boolean;
    idOrTaxNo;
}
