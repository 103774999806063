import { SubscriptionAddress } from "./subscription-address";

export class SubscriptionCustomerData {
    referenceCode: string;
    createdDate: string;
    status: string;
    name: string;
    surname: string;
    identityNumber: string;
    email: string;
    gsmNumber: string;
    billingAddress: SubscriptionAddress;
    shippingAddress: SubscriptionAddress;

    constructor() {
        this.billingAddress = new SubscriptionAddress();
        this.shippingAddress = new SubscriptionAddress();
    }
}