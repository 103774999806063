import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Response } from "src/app/domain/base/response";
import { Product } from "src/app/domain/experience/product/product";
import { CreateProductRequest } from "src/app/domain/experience/product/request/create-product-request";
import { DeleteProductRequest } from "src/app/domain/experience/product/request/delete-product-request";
import { RetrieveProductListPageRequest } from "src/app/domain/experience/product/request/retireve-product-list-page-request";
import { RetrieveProductWithIdRequest } from "src/app/domain/experience/product/request/retrieve-product-with-id-request";
import { RetrieveProductListWithKeywordPageRequest } from "src/app/domain/experience/product/request/retrieve-product-list-with-keyword-page-request";
import { UpdateProductRequest } from "src/app/domain/experience/product/request/update-product-request";
import { HttpManager } from "src/app/managers/http.manager";
import { parameters } from "src/app/parameters/parameters";
import { RetrieveProductLisWithOrganizationIdPageRequest } from "src/app/domain/experience/product/request/retrieve-product-list-with-organization-id-page-request";
import { RetrieveProductListWithDomainIdsPageRequest } from "src/app/domain/experience/product/request/retrieve-product-list-with-domain-ids-page-request";
import { RetrieveProductListWithOwnerIdPageRequest } from "src/app/domain/experience/product/request/retrieve-product-list-with-owner-id-page-request";


const childPath = "product/";

@Injectable()
export class ProductService {

    constructor(private httpManager: HttpManager) {

    }

    /**
     * creates a product
     * @param {Product} product 
     * @returns 
     */
    create(product: Product): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "create", new CreateProductRequest(product)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        )
    }

    /**
     * updates a product. 
     * @param {Product} product  
     * @returns 
     */
    update(product: Product): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "update", new UpdateProductRequest(product)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        )
    }

    /**
     * deletes a product.
     * @param {string} id 
     * @returns 
     */
    delete(id: string): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "delete", new DeleteProductRequest(id)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        )
    }

    /**
     * lists the products.
     * @param {number} phase 
     * @param {number} size 
     * @returns 
     */
    list(phase: number, size: number): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "list", new RetrieveProductListPageRequest(phase, size)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        )
    }

    /**
     * retrieves products with id.
     * @param {string} id 
     * @returns 
     */
    getById(id: string): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "get-by-id", new RetrieveProductWithIdRequest(id)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        )
    }


    /**
     * retrieves product list with organization id.
     * @param {number} phase 
     * @param {number} size 
     * @param {string} organizationId 
     * @returns 
     */
    listByOrganizationId(phase: number, size: number, organizationId: string): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "list-by-organization-id", new RetrieveProductLisWithOrganizationIdPageRequest(phase, size, organizationId)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        )
    }
   
    /**
    * retrieves product list with owner id.
    * @param {number} phase 
    * @param {number} size 
    * @param {string} ownerId 
    * @returns 
    */
    listByOwnerId(phase: number, size: number, ownerId: string): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "list-by-owner-id", new RetrieveProductListWithOwnerIdPageRequest(phase, size, ownerId)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        )
    }

     /**
    * retrieves product list with domain ids.
    * @param {number} phase 
    * @param {number} size 
    * @param {string[]} domainIds 
    * @returns 
    */
      listByDomainIds(phase: number, size: number, domainIds: string[]): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "list-by-domain-ids", new RetrieveProductListWithDomainIdsPageRequest(phase, size, domainIds)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        )
    }

    /**
     * retrieves product with keyword.
     * @param {number} phase 
     * @param {number} size 
     * @param {string} keyword 
     * @returns 
     */
    search(phase: number, size: number, keyword: string): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "search", new RetrieveProductListWithKeywordPageRequest(phase, size, keyword)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        )
    }

}