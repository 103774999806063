import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-qrcode-screen',
  templateUrl: './qrcode-screen.modal.html',
  styleUrls: ['./qrcode-screen.modal.scss'],
})
export class QrcodeScreenModal implements OnInit {

  @Input("url") url: string;

  constructor(public modalController: ModalController,
    public languageService: LanguageService) { }

  ngOnInit() {
  }

}
