import { Request } from "src/app/domain/base/request";
import { SaasAddress } from "../../saas-address";

export class UpdateSaasAddressRequest extends Request {
    saasAddress : SaasAddress;

  constructor(saasAddress: SaasAddress) {
    super();
    this.saasAddress = saasAddress
  }

};
