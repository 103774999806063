import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SaasPricingPlan } from "src/app/domain/saas/saas-pricing-plan";
import { PricingPlanDto } from "src/app/dto/request/pricing-plan.dto";
import { HttpManager } from "src/app/managers/http.manager";
import { parameters } from "src/app/parameters/parameters";

const childPath = "pricing-plan/";

@Injectable()
export class SaasPricingPlanService {
    constructor(private httpManager: HttpManager) {
    }

    create(pricingPlan: SaasPricingPlan): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "create", pricingPlan).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    getByPricingPlanDto(pricingPlanDto: PricingPlanDto): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "get-by-pricing-plan-dto", pricingPlanDto).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }
}