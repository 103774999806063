import { Request } from "src/app/domain/base/request";
import { Account } from "../account";

export class CreateAccountWithDomainIdRequest extends Request {
    account: Account
    
    constructor(account: Account) {
        super();
        this.account = account
    }

};
