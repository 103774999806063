import { Injectable } from "@angular/core";
import { SubscriptionAddress } from "../domain/iyzico/subscription-address";
import { SubscriptionCardData } from "../domain/iyzico/subscription-card-data";
import { SubscriptionCustomerData } from "../domain/iyzico/subscription-customer-data";
import { SaasPricingPlan } from "../domain/saas/saas-pricing-plan";
import { SubscriptionDto } from "../dto/request/subscription.dto";
import { SaasSubscriptionService } from "./saas/saas-subscription.service";
import { AuthService } from "./yon/auth/auth.service";

@Injectable()
export class CheckoutService {
    pricingPlan: SaasPricingPlan;
    subscriptionCustomer: SubscriptionCustomerData;
    formValid: boolean = false;
    basketValid: boolean = true;
    addressFormValid: boolean = false;
    paymentFormValid: boolean = false;
    cardData: SubscriptionCardData;
    cardType = "visa";
    cardNumber = "";
    months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
    years = ["2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035"];
    current: number = 1;
    pages = [
        {
            name: "Özet",
            link: "/checkout/basket",
            direction: "forward",
            disabled: false,
            point: 1
        },
        {
            name: "Bilgiler",
            link: "/checkout/address",
            direction: "forward",
            disabled: false,
            point: 2
        },
        {
            name: "Ödeme",
            link: "/checkout/payment",
            direction: "forward",
            disabled: false,
            point: 3
        }
    ];

    constructor(private authService: AuthService,
        private saasSubscriptionService: SaasSubscriptionService) {
        this.subscriptionCustomer = new SubscriptionCustomerData();
        this.cardData = new SubscriptionCardData();
        this.subscriptionCustomer.billingAddress.country = "Türkiye";
    }

    initializeSubscription() {
        let subscriptionDto: SubscriptionDto = new SubscriptionDto();
        subscriptionDto.subscriptionCardData = this.cardData;
        subscriptionDto.subscriptionCustomerData = this.subscriptionCustomer;
        subscriptionDto.subscriptionCustomerData.shippingAddress = subscriptionDto.subscriptionCustomerData.billingAddress;
        subscriptionDto.subscriptionData.pricingPlanReferenceCode = this.pricingPlan.subscriptionPricingPlanData.referenceCode;
        subscriptionDto.initialStatus = "ACTIVE";
        subscriptionDto.accountId = this.authService.account.id;

        
    }
}