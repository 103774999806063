import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SubscriptionFormComponent } from './subscription-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import {ScrollDirectiveModule} from '../../../directives/scroll/scroll.directive.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        ScrollDirectiveModule,
    ],
    declarations: [SubscriptionFormComponent],
    exports: [SubscriptionFormComponent]
})

export class SubscriptionFormModule { }
