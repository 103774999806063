import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
/** Simulate server replying to file upload request */
@Injectable()
export class DomainInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes("login")||req.url.includes("register")||req.url.includes("validate")) {            
            return next.handle(
                req.clone({
                    headers: req.headers.append('Domain', "6164074ac86f7511e0acee35")
                })
            );
        }
        else {            
            return next.handle(req.clone());            
        }
    }
}