import { SubscriptionOrderData } from "./subscription-order-data";

export class SubscriptionData {
    referenceCode: string;
    parentReferenceCode: string;
    pricingPlanName: string;
    pricingPlanReferenceCode: string;
    productName: string;
    productReferenceCode: string;
    customerEmail: string;
    customerReferenceCode: string;
    customerGsmNumber: string;
    paymentId: number;
    subscriptionStatus: string;
    trialDays: number;
    trialStartDate: string;
    trialEndDate: string;
    canceledAt: string;
    createdDate: string;
    startDate: string;
    endDate: string;
    subscriptionOrders: SubscriptionOrderData[];
}