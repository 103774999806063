import { PageRequest } from "src/app/domain/base/page-request";

export class RetrieveProductListWithDomainIdsPageRequest extends PageRequest {

    domainIds: string[];

    constructor(phase: number, size: number, domainIds: string[]) {
        super(phase, size);
        this.domainIds = domainIds;
    }
}