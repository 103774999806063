/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable curly */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/component-selector */
import { InterfaceService } from 'src/app/services/yon/auth/interface.service';
import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, HostListener } from '@angular/core';
import { Platform, MenuController, IonCard } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/yon/auth/auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'vt-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  @Input("items") items;

  @ViewChild('submenu', { static: true })
  public submenu: ElementRef<HTMLDivElement>;

  public subItems: [];

  public darkModeOn: boolean;
  public submenuOpen: boolean = false;
  public submenuJustOpen: boolean = false;
  public openedItemName: string = "";

  private sideMenuJustClosed = false;

  options = {
    header: this.langService.translate("Dil"),
    translucent: true
  };

  headerBackgroundActive: boolean = false;

  constructor(public platform: Platform,
    public router: Router,
    private renderer2: Renderer2,
    private menu: MenuController,
    public authService: AuthService,
    private interfaceService: InterfaceService,
    public langService: LanguageService) {
    if (window) {
      this.darkModeOn =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches;
    }


    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)).subscribe(
        x => {
          this.headerBackgroundActive = false;
        });
  }

  ngOnInit() {
    this.items.forEach(item => {
      item.expanded = false;
    });
    this.options.header = this.langService.translate("Dil");

    this.interfaceService.headerSubject.subscribe(
      (value) => {
        if (value > 0) {
          this.headerBackgroundActive = true;
        }
        else {
          this.headerBackgroundActive = false;
        }
      }
    );
  }

  openMenu() {
    this.sideMenuJustClosed = false;
    this.menu.enable(true, 'mainMenu');
    this.menu.open('mainMenu');
  }

  closeMenu() {
    this.menu.close('mainMenu');
    this.sideMenuJustClosed = true;
    setTimeout(() => {
      if (this.sideMenuJustClosed)
        this.menu.enable(false, 'mainMenu');
    }, 1000);
  }

  expandItem(item) {
    this.items.map(listItem => {
      if (item === listItem) {
        listItem.expanded = !listItem.expanded;
      } else {
        listItem.expanded = false;
      }
      return listItem;
    });
  }

  closeSubmenu() {
    if (this.submenuJustOpen) {
      this.submenuJustOpen = false;
    }
    else {
      this.renderer2.removeClass(this.submenu.nativeElement, "opened");
      this.submenuOpen = false;
    }
  }

  processSubmenu(e) {
    let item = this.items.find(i => this.langService.translate(i.name).replace(" ", "") === e.target.innerText.replace(" ", ""));

    if (this.submenuOpen && item.children == null) {
      if (this.openedItemName !== this.langService.translate(item.name)) {
        this.renderer2.removeClass(this.submenu.nativeElement, "opened");
        this.submenuOpen = false;
      }
    }
    else if (this.submenuOpen && item.children != null) {
      this.closeSubmenu();
    }
    else if (item.children != null) {
      this.subItems = item.children;
      this.renderer2.setStyle(this.submenu.nativeElement, "left", (this.getOffset(e.target).left - 5.6) + "px");
      this.renderer2.setStyle(this.submenu.nativeElement, "top", this.getOffset(e.target).top + "px");
      this.renderer2.addClass(this.submenu.nativeElement, "opened");
      this.submenuOpen = true;
      this.submenuJustOpen = true;
      this.openedItemName = this.langService.translate(item.name);
    }
  }

  onmouseup(e) {
    //this.renderer2.removeClass(this.submenu.nativeElement, "opened")

  }

  getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }

  //@HostListener("window:mouseover", ['$event'])
  universalMouseover(e) {
    if (!e.target.className.includes("submenu-container") && !e.target.className.includes("submenu") && !e.target.className.includes("submenu__content") && !e.target.className.includes("submenu__item") && !e.target.className.includes("submenu__icon") && !e.target.className.includes("in-toolbar") && !e.target.className.includes("nav-link") && this.submenuOpen) {
      this.renderer2.removeClass(this.submenu.nativeElement, "opened");
      this.submenuOpen = false;
    }
  }

  @HostListener("window:click", ['$event'])
  universalClick(e) {
    this.closeSubmenu();
  }
}
