/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { IonSlides, ModalController, Platform } from '@ionic/angular';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-hotspot-preview',
  templateUrl: './hotspot-preview.modal.html',
  styleUrls: ['./hotspot-preview.modal.scss'],
})
export class HotspotPreviewModal implements OnInit, AfterViewInit {

  @Input() content: string;
  @Input() contentType: string;

  @ViewChild('content', { static: true })
  public ionContent: ElementRef;

  @ViewChild('container', { static: true })
  public container: ElementRef;

  @ViewChild('myPinch', { static: false })
  public zoom;

  @ViewChild('slider', { static: false })
  public slider: IonSlides;

  sliderOpt = {
    zoom: {
      maxRatio: 3,
      toggle: false
    },
  };
  constructor(public platform: Platform,
    public modalController: ModalController,
    public langService: LanguageService) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {

  }

  ionViewDidEnter() {
    this.slider.update().then(
      (val) => {
        if (this.contentType === 'image') {
          this.createImg();
        }
        else if (this.contentType === 'video') { this.createIFrame(); }
      }
    );
    this.slider.options = this.sliderOpt;
  }

  createImg() {
    //img to show image
    const img = document.createElement('img');
    img.src = this.content;
    img.className = 'hotspot-preview hotspot-preview__image';

    //Append img to img container
    //this.ionContent["el"].appendChild(img);
    this.container.nativeElement.appendChild(img);
  }

  createIFrame() {
    console.log(this.ionContent);
    //iframe to show video
    const iframe = document.createElement('iframe');
    iframe.className = 'hotspot-preview hotspot-preview__video';
    iframe.src = this.content;
    //iframe.setAttribute("allowfullscreen", "");
    iframe.setAttribute('frameborder', '0');

    iframe.append('<style>.ytp-chrome-top{display:none;}</style>');

    //Append iframe to ionContent
    this.ionContent['el'].appendChild(iframe);
  }
}
