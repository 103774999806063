import { Request } from "src/app/domain/base/request";

export class ValidateUsernameRequest extends Request {
    username: string;

    constructor(username: string) {
        super();
        this.username = username
    }

};
