import { Request } from "src/app/domain/base/request";
import { Product } from "../product";

export class CreateProductRequest extends Request {

    product: Product;

    constructor(product: Product) {
        super();
        this.product = product;
    }
}