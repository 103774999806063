import { Base } from "../base";
import { CreatedSubscriptionData } from "../iyzico/created-subscription-data";
import { SubscriptionData } from "../iyzico/subscription-data";
import { SaasCustomer } from "./saas-customer";
import { SaasPricingPlan } from "./saas-pricing-plan";

export class SaasSubscription extends Base {
    createdSubscriptionData: CreatedSubscriptionData;
    subscriptionData: SubscriptionData;
    status: string;
    pricingPlan: SaasPricingPlan;
    customer: SaasCustomer;
    saasPricingPlanId: string;
    saasCustomerId: string;


    constructor() {
        super();
        this.createdSubscriptionData = new CreatedSubscriptionData();
        this.subscriptionData = new SubscriptionData();
        this.pricingPlan = new SaasPricingPlan();
        this.customer = new SaasCustomer();
    }
}