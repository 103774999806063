import { UpdateTourRequest } from './../../../domain/experience/tour/request/update-tour-request';
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpManager } from 'src/app/managers/http.manager';
import { Tour } from '../../../domain/experience/tour/tour';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { PageRequestDto } from 'src/app/dto/request/page-request.dto';
import { parameters } from '../../../parameters/parameters';
import { GenericRequest } from 'src/app/dto/request/generic-request.dto';
import { CreateTourRequest } from 'src/app/domain/experience/tour/request/create-tour-request';
import { DeleteTourRequest } from 'src/app/domain/experience/tour/request/delete-tour-request';
import { RetrieveTourListWithKeywordPageRequest } from 'src/app/domain/experience/tour/request/retrieve-tour-list-with-keyword-page-request';
import { RetrieveTourListPageRequest } from 'src/app/domain/experience/tour/request/retrieve-tour-list-page-request';
import { RetrieveTourRequest } from 'src/app/domain/experience/tour/request/retrieve-tour-request';
import { Response } from 'src/app/domain/base/response';

const childPath = "tour/";

@Injectable()
export class TourService {
    constructor(private httpManager: HttpManager) {
    }

    /**
     * @param  {Tour} tour
     * @returns Observable
     */
    create(tour: Tour): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "create", new CreateTourRequest(tour)).pipe(
            map(
                (response: Response) => {
                    return response.data;
                }
            )
        );
    }

    /**
     * @param  {Tour} tour
     * @returns Observable
     */
    update(tour: Tour): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "update", new UpdateTourRequest(tour)).pipe(
            map(
                (response: Response) => {
                    return response.data;
                }
            )
        );
    }

    /**
     * @param  {string} id
     * @returns Observable
     */
    delete(id: string): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "delete", new DeleteTourRequest(id)).pipe(
            map(
                (response: Response) => {
                    return response.data;
                }
            )
        );
    }

    /**
     * @param  {number} phase
     * @param  {number} size
     * @param  {string} keyword
     * @returns Observable
     */
    search(phase: number, size: number, keyword: string): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "search", new RetrieveTourListWithKeywordPageRequest(phase, size, keyword)).pipe(
            map(
                (response: Response) => {
                    return response.data;
                }
            )
        );
    }

    /**
     * @param  {number} phase
     * @param  {number} size
     * @returns Observable
     */
    list(size: number, phase: number): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "list", new RetrieveTourListPageRequest(phase, size)).pipe(
            map(
                (response: Response) => {
                    return response.data;
                }
            )
        );
    }

    /**
     * @returns Observable
     */
    listByOwnerId(): Observable<any> {
        return this.httpManager.postWithoutData(parameters.server + childPath + "list-by-owner-id").pipe(
            map(
                (response: Response) => {
                    return response.data;
                }
            )
        );
    }

    /**
     * @param  {string} id
     * @returns Observable
     */
    getById(id: string): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "get-by-id", new RetrieveTourRequest(id)).pipe(
            map(
                (response: Response) => {
                    return response.data;
                }
            )
        );
    }
}