<ion-menu side="end" menuId="mainMenu" contentId="menuContent" swipeGesture="false">
  <ion-header>
    <ion-toolbar>
      <!-- <ion-button fill="clear" expand="full" [routerLink]="'/'" [routerDirection]="'root'" (click)="closeMenu()">
        <ion-icon *ngIf="!darkModeOn" class="logotype" slot="icon-only" src="assets/icon/logotype-dark.svg"></ion-icon>
        <ion-icon *ngIf="darkModeOn" class="logotype" slot="icon-only" src="assets/icon/logotype-light.svg"></ion-icon>
      </ion-button> -->
      <ion-buttons slot="end">
        <ion-button fill="clear" (click)="closeMenu()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <div *ngFor="let item of items">
        <ion-item *ngIf="item.children===undefined" [routerLink]="item.link" [routerDirection]="'root'" detail="false"
          lines="none" [class.active]="router.url.startsWith(item.link)" class="nav-link" (click)="closeMenu()">
          {{langService.translate(item.name)}}
          <ion-icon slot="end" name="arrow-forward-outline"></ion-icon>
        </ion-item>
        <ion-item class="nav-link" *ngIf="item.children!==undefined" (click)="expandItem(item)" detail="false" lines="none"
          [class.active]="router.url.startsWith(item.link)">
          {{langService.translate(item.name)}}
          <ion-icon *ngIf="!item.expanded" slot="end" name="add-outline"></ion-icon>
          <ion-icon *ngIf="item.expanded" slot="end" name="remove-outline"></ion-icon>
        </ion-item>
        <expandable *ngIf="item.children!==undefined" [expandHeight]="item.children?.length * 54 + 'px'"
          [expanded]="item.expanded">
          <ion-item class="nav-link__subitem" *ngFor="let child of item.children" [routerLink]="child.link"
            [routerDirection]="'root'" detail="false" lines="none" [class.active]="router.url.startsWith(item.link)"
            (click)="expandItem(item);closeMenu();">
            {{langService.translate(child.name)}}
            <ion-icon *ngIf="child.icon!==undefined" class="symbol" slot="start" [src]="child.icon"></ion-icon>
            <ion-icon slot="end" name="arrow-forward-outline"></ion-icon>
          </ion-item>
        </expandable>
      </div>

      <div class="nav-link-divider"> </div>
      <ion-item class="lang-selector-container" lines="none">
        <ion-icon slot="start" [src]="langService.getCurrentLanguageFlag()"></ion-icon>

        <ion-select class="lang-selector" [value]="langService.getCurrentLanguageCode()"
          [okText]="langService.translate('Seç')" [cancelText]="langService.translate('Vazgeç')" interface="alert"
          [interfaceOptions]="options" (ionChange)="langService.changeLanguage($event.target['value'])">

          <ion-select-option *ngFor="let lang of langService.getLanguages()" [value]="lang['code']">{{lang['name']}}
          </ion-select-option>
        </ion-select>
      </ion-item>

    </ion-list>
  </ion-content>
  <ion-footer>
    <!-- <ion-button *ngIf="authService.account.username==''" expand="block" class="cta-link" fill="outline" color="primary"
      routerLink="auth" [routerDirection]="'forward'">
      {{langService.translate("Giriş Yap")}}
    </ion-button>
    <ion-button *ngIf="authService.account.username!=''" expand="block" class="nav-link" fill="clear" color="primary"
      routerLink="panel" [routerDirection]="'root'">
      {{authService.account.name}} {{authService.account.surname}}
      <ion-icon class="assistive chevron" slot="end" name="chevron-down-outline" color="primary"></ion-icon>
    </ion-button> -->
  </ion-footer>
  <div class="back-filter"></div>
</ion-menu>

<ion-header [translucent]="headerBackgroundActive" [class.transparent]="!headerBackgroundActive">
  <ion-toolbar class="container">
    <ion-buttons slot="start">

      <ion-button [routerLink]="'/'" [routerDirection]="'root'" class="brandmark-button">
        <ion-icon class="logotype" slot="icon-only" src="assets/icon/logotype-dark.svg"></ion-icon>
        <!-- <ion-icon *ngIf="darkModeOn" class="logotype" slot="icon-only" src="assets/icon/logotype-light.svg"></ion-icon> -->
      </ion-button>
    </ion-buttons>
    <ion-buttons class="nav-links" *ngIf="platform.width() >= 1024">
      <div *ngFor="let item of items" (click)="processSubmenu($event)">
        <ion-button *ngIf="item.children===undefined" [routerLink]="item.link" [routerDirection]="'root'"
          [class.active]="router.url.startsWith(item.link)" class="nav-link" fill="clear">
          {{langService.translate(item.name)}}
        </ion-button>
        <ion-button *ngIf="item.children!==undefined" [class.active]="router.url.startsWith(item.link)"
          [class.opened]="submenuOpen" class="nav-link" fill="clear">
          {{langService.translate(item.name)}}
          <ion-icon name="chevron-down-outline" class="chevron"></ion-icon>
        </ion-button>
      </div>
      <div class="nav-link-divider"> </div>
      <div>
        <!-- <ion-button class="nav-link lang-selector" fill="clear">
          {{langService.getCurrentLanguageCode()}}

        </ion-button> -->
        <ion-select class="lang-selector" [value]="langService.getCurrentLanguageCode()"
          [okText]="langService.translate('Seç')" [cancelText]="langService.translate('Vazgeç')" interface="alert"
          [interfaceOptions]="options" (ionChange)="langService.changeLanguage($event.target['value'])">
          <ion-select-option *ngFor="let lang of langService.getLanguages()" [value]="lang['code']">{{lang['code']}}
          </ion-select-option>
        </ion-select>
      </div>
    </ion-buttons>

    <ion-buttons slot="end" *ngIf="platform.width() >= 768">
      <ion-button *ngIf="authService.account.username===''" class="cta-link" fill="outline" color="primary"
        routerLink="auth" [routerDirection]="'forward'">
        {{langService.translate("Giriş Yap")}}
      </ion-button>
      <ion-button *ngIf="authService.account.username===''" class="cta-link" fill="solid" color="primary"
        [href]="'/#/experience/product/61aa47bd6993730ec3d13803'" [target]="'_blank'">
        {{langService.translate("experienceButton")}}
        <!-- <ion-icon class="assistive" slot="end" name="arrow-forward-outline" ></ion-icon> -->
      </ion-button>
      <ion-button *ngIf="authService.account.username!==''" class="nav-link" fill="clear" color="primary"
        routerLink="panel" [routerDirection]="'root'">

        <!-- <ion-icon slot="start" name="person-outline" color="primary"></ion-icon> -->
        {{authService.account.name}} {{authService.account.surname}}
        <ion-icon class="assistive chevron" slot="end" name="chevron-down-outline" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="menu-button" fill="clear" *ngIf="platform.width() < 1024" (click)="openMenu()">
        <ion-icon class="menu-icon" slot="icon-only" src="assets/icons/menu.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div #submenu class="submenu-container">
  <!-- <div class="submenu-background"></div> -->

  <ion-card class="submenu">

    <ion-card-content class="submenu__content">
      <ion-item *ngFor="let subitem of subItems" class="submenu__item" class="submenu__item" lines="none" detail="false"
        [routerLink]="subitem['link']" routerDirection="root">
        <ion-icon *ngIf="subitem['icon']!==null" class="submenu__icon" [src]="subitem['icon']"></ion-icon>
        {{langService.translate(subitem['name'])}}
      </ion-item>
    </ion-card-content>
  </ion-card>
</div>
