import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { count } from 'console';
import { Response } from 'src/app/domain/base/response';


import { SaasAddress } from 'src/app/domain/saas/saas-address';
import { PanelService } from 'src/app/panel/services/panel.service';
import { LanguageService } from 'src/app/services/language.service';
import { SaasAddressService } from 'src/app/services/saas/saas-address-service';
import { AccountService } from 'src/app/services/yon/auth/account.service';
import { AuthService } from 'src/app/services/yon/auth/auth.service';


@Component({
    selector: 'app-subscription-form',
    templateUrl: './subscription-form.component.html',
    styleUrls: ['./subscription-form.component.scss'],
})
export class SubscriptionFormComponent {

    // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform

    isCreate;
    isPersonal;
    saasAddressPersonal: SaasAddress;
    saasAddressOrganizational: SaasAddress;

    public segmentValue = 'company';
    public organizationalAddressForm: FormGroup;
    public personalAddressForm: FormGroup;

    constructor(public alertController: AlertController,
        public languageService: LanguageService,
        private saasAddressService: SaasAddressService,
        private authService: AuthService,
        private panelService: PanelService) {
        this.saasAddressPersonal = new SaasAddress();
        this.saasAddressOrganizational = new SaasAddress();
        this.isCreate = panelService.isCreateAddress;        

        if (panelService.isCreateAddress === false) {

            if (panelService.selectedSaasAddress.personal) {
                this.saasAddressPersonal = panelService.selectedSaasAddress;
                this.segmentValue = 'personal';
            } else {
                this.saasAddressOrganizational = panelService.selectedSaasAddress;
                this.segmentValue = 'company';
            }
            console.log('here 2');

        }
        this.organizationalAddressForm = new FormGroup({
            addressName: new FormControl(this.saasAddressOrganizational.addressName,
                {
                    validators: [Validators.required,
                    Validators.maxLength(15),
                    ]
                }
            ),
            name: new FormControl(this.saasAddressOrganizational.name,
                {
                    validators: [Validators.required,
                    Validators.maxLength(50),
                    Validators.pattern('^[a-zA-Z]+$')
                    ]
                }
            ),
            surname: new FormControl(this.saasAddressOrganizational.surname,
                {
                    validators: [Validators.required,
                    Validators.pattern('^[a-zA-Z]+$')
                    ]
                }
            ),
            gsmNumber: new FormControl(this.saasAddressOrganizational.gsmNo, {
                validators: [
                    Validators.required,
                    Validators.pattern(/^[0-9]\d*$/)
                    // Validators.minLength(11),
                    // Validators.maxLength(14)
                ]
            }),
            taxNo: new FormControl(this.saasAddressOrganizational.taxNo, {
                validators: [
                    Validators.required,
                    Validators.maxLength(11),
                    Validators.minLength(10),
                ],
                // updateOn: 'blur'
            }
            ),
            taxOffice: new FormControl(this.saasAddressOrganizational.taxOffice, {
                validators: [
                    Validators.required,
                    Validators.maxLength(100),
                    // Validators.minLength(10),
                ],
                // updateOn: 'blur'
            }
            ),
            organizationTitle: new FormControl(this.saasAddressOrganizational.organizationTitle, {
                validators: [
                    Validators.required,
                    Validators.maxLength(200),
                    // Validators.minLength(10),
                ],
                // updateOn: 'blur'
            }
            ),

            country: new FormControl(this.saasAddressOrganizational.country, {
                validators: [
                    Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }),
            state: new FormControl(this.saasAddressOrganizational.state, {
                validators: [
                    // Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }),
            city: new FormControl(this.saasAddressOrganizational.city, {
                validators: [
                    Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }),
            address: new FormControl(this.saasAddressOrganizational.address, {
                validators: [
                    Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }),
            district: new FormControl(this.saasAddressOrganizational.district, {
                validators: [
                    Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }),
            zipCode: new FormControl(this.saasAddressOrganizational.zipCode, {
                validators: [
                    Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }),
        });
        this.personalAddressForm = new FormGroup({
            addressName: new FormControl(this.saasAddressPersonal.addressName,
                {
                    validators: [Validators.required,
                    Validators.maxLength(15),
                    ]
                }
            ),
            name: new FormControl(this.saasAddressPersonal.name,
                {
                    validators: [Validators.required,
                    Validators.maxLength(50),
                    Validators.pattern('^[a-zA-Z]+$')
                    ]
                }
            ),
            surname: new FormControl(this.saasAddressPersonal.surname,
                {
                    validators: [Validators.required,
                    Validators.pattern('^[a-zA-Z]+$')
                    ]
                }
            ),
            identityNumber: new FormControl(this.saasAddressPersonal.identityNo, {
                validators: [
                    Validators.required,
                    Validators.maxLength(11),
                    Validators.pattern(/^[0-9]\d*$/)
                ],
                // updateOn: 'blur'
            }
            ),
            gsmNumber: new FormControl(this.saasAddressPersonal.gsmNo, {
                validators: [
                    Validators.required,
                    // Validators.pattern(/^[0-9]\d*$/)
                    // Validators.minLength(11),
                    // Validators.maxLength(14)
                ]
            }),
            country: new FormControl(this.saasAddressPersonal.country, {
                validators: [
                    Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }), state: new FormControl(this.saasAddressPersonal.state, {
                validators: [
                    // Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }),
            city: new FormControl(this.saasAddressPersonal.city, {
                validators: [
                    Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }),
            district: new FormControl(this.saasAddressPersonal.district, {
                validators: [
                    Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }),
            address: new FormControl(this.saasAddressPersonal.address, {
                validators: [
                    Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }),
            zipCode: new FormControl(this.saasAddressPersonal.zipCode, {
                validators: [
                    Validators.required,
                    // Validators.minLength(2),
                    // Validators.maxLength(50),
                ]
            }),
        });
        // this.personalAddressForm = new FormGroup({
        //     addressName: new FormControl('Ev',
        //         {
        //             validators: [Validators.required,
        //             Validators.maxLength(15),
        //             ]
        //         }
        //     ),
        //     name: new FormControl('Mert',
        //         {
        //             validators: [Validators.required,
        //             Validators.maxLength(50),
        //             Validators.pattern('^[a-zA-Z]+$')
        //             ]
        //         }
        //     ),
        //     surname: new FormControl('Ellialti',
        //         {
        //             validators: [Validators.required,
        //             Validators.pattern('^[a-zA-Z]+$')
        //             ]
        //         }
        //     ),
        //     identityNumber: new FormControl('50686564044', {
        //         validators: [
        //             Validators.required,
        //             Validators.maxLength(11),
        //             Validators.pattern(/^[0-9]\d*$/)
        //         ],
        //         // updateOn: 'blur'
        //     }
        //     ),
        //     gsmNumber: new FormControl('+905345296408', {
        //         validators: [
        //             Validators.required,
        //             // Validators.pattern(/^[0-9]\d*$/)
        //             // Validators.minLength(11),
        //             // Validators.maxLength(14)
        //         ]
        //     }),
        //     country: new FormControl('Turkiye', {
        //         validators: [
        //             Validators.required,
        //             // Validators.minLength(2),
        //             // Validators.maxLength(50),
        //         ]
        //     }),
        //     state: new FormControl('', {
        //         validators: [
        //             // Validators.required,
        //             // Validators.minLength(2),
        //             // Validators.maxLength(50),
        //         ]
        //     }),
        //     city: new FormControl('Ankara', {
        //         validators: [
        //             Validators.required,
        //             // Validators.minLength(2),
        //             // Validators.maxLength(50),
        //         ]
        //     }),
        //     district: new FormControl('Cankaya', {
        //         validators: [
        //             Validators.required,
        //             // Validators.minLength(2),
        //             // Validators.maxLength(50),
        //         ]
        //     }),
        //     address: new FormControl('33. Sokak 4/5', {
        //         validators: [
        //             Validators.required,
        //             // Validators.minLength(2),
        //             // Validators.maxLength(50),
        //         ]
        //     }),
        //     zipCode: new FormControl('06500', {
        //         validators: [
        //             Validators.required,
        //             // Validators.minLength(2),
        //             // Validators.maxLength(50),
        //         ]
        //     }),
        // });
    }

    submitForm() {
        this.formToSaasAddress();
        if (this.segmentValue === 'personal') {
            this.saasAddressService.create(this.saasAddressPersonal).subscribe(
                (res: Response) => {
                    if (res.status) {
                        console.log('success');
                    } else {
                        console.log('failure');
                    }
                }
            );
        } else {
            this.saasAddressService.create(this.saasAddressOrganizational).subscribe(
                (res: Response) => {
                    if (res.status) {
                        console.log('success');
                    } else {
                        console.log('failure');
                    }
                }
            );
        }
    }

    updateForm() {
        this.formToSaasAddress();
        if (this.panelService.selectedSaasAddress.personal) {
            this.saasAddressService.update(this.saasAddressPersonal).subscribe(
                (res: Response) => {
                    if (res.data) {
                        this.presentAlert(this.saasAddressPersonal);
                        // console.log(this.modalController);
                        console.warn('Updating Address Successful.');
                    }
                }
            );
        } else {
            this.saasAddressService.update(this.saasAddressOrganizational).subscribe(
                (res: Response) => {
                    if (res.data) {
                        this.presentAlert(this.saasAddressPersonal);
                        // console.log(this.modalController);
                        console.warn('Updating Address Successful.');
                    }
                }
            );
        }
    }

    /**
     * This method prevents adding special characters to input field.
     * // tslint:disable-next-line:no-redundant-jsdoc
     * @param: event
     */
    omit_special_char(event) {
        let k;
        k = event.charCode;  //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
    }

    allowCharOnly(event) {
        let k;
        k = event.charCode;
        return ((k <= 122 && k >= 90) || (k >= 65 && k <= 90));
    }

    allowNumbersOnly(event) {
        let k;
        k = event.charCode;
        return ((k >= 48 && k <= 57));
    }

    allowPhoneNumberOnly(event) {
        let k;
        k = event.charCode;
        return ((k >= 48 && k <= 57) || k === 43);
    }

    segmentChanged(e) {
        this.segmentValue = e.detail.value;
        if (e.detail.value === 'personal') {
            this.saasAddressPersonal.personal = true;
            this.saasAddressOrganizational.personal = false;
            console.log(this.saasAddressOrganizational);
            console.log(this.saasAddressPersonal);
        } else if (e.detail.value === 'company') {
            this.saasAddressOrganizational.personal = true;
            this.saasAddressPersonal.personal = false;
        }
    }

    async presentAlert(saasAddressPersonal: SaasAddress) {

        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Bildirim',
            subHeader: 'Adres başarıyla güncellendi',
            message: ' ' + saasAddressPersonal.addressName + ' ',
            buttons: ['OK']
        });

        await alert.present();

        const { role } = await alert.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
    }

    errors() {
    }

    submit() {
    }

    formToSaasAddress() {

        if (this.segmentValue === 'company') {
            this.saasAddressOrganizational.addressName = this.organizationalAddressForm.value.addressName;
            this.saasAddressOrganizational.name = this.organizationalAddressForm.value.name;
            this.saasAddressOrganizational.surname = this.organizationalAddressForm.value.surname;
            this.saasAddressOrganizational.contactName = this.saasAddressOrganizational.name + ' ' +
                this.saasAddressOrganizational.surname;
            this.saasAddressOrganizational.gsmNo = this.organizationalAddressForm.value.gsmNumber;
            this.saasAddressOrganizational.email = this.authService.account.email;
            this.saasAddressOrganizational.active = true;
            this.saasAddressOrganizational.ownerId = this.authService.account.id;
            this.saasAddressOrganizational.taxNo = this.organizationalAddressForm.value.taxNo;
            this.saasAddressOrganizational.taxOffice = this.organizationalAddressForm.value.taxOffice;
            this.saasAddressOrganizational.organizationTitle = this.organizationalAddressForm.value.organizationTitle;
            this.saasAddressOrganizational.country = this.organizationalAddressForm.value.country;
            this.saasAddressOrganizational.state = this.organizationalAddressForm.value.state;
            this.saasAddressOrganizational.city = this.organizationalAddressForm.value.city;
            this.saasAddressOrganizational.district = this.organizationalAddressForm.value.district;
            this.saasAddressOrganizational.address = this.organizationalAddressForm.value.address;
            this.saasAddressOrganizational.zipCode = this.organizationalAddressForm.value.zipCode;
            if (this.saasAddressOrganizational.domainIds == null) {
                let domainIds: Array<string>;
                domainIds = [this.authService.account.domainIds[0]];
                console.log(this.authService.account.domainIds[0]);
                this.saasAddressOrganizational.domainIds = domainIds;
            }
        }
        else if (this.segmentValue === 'personal') {
            this.saasAddressPersonal.addressName = this.personalAddressForm.value.addressName;
            this.saasAddressPersonal.name = this.personalAddressForm.value.name;
            this.saasAddressPersonal.surname = this.personalAddressForm.value.surname;
            this.saasAddressPersonal.contactName = this.saasAddressPersonal.name + ' ' +
                this.saasAddressPersonal.surname;
            this.saasAddressPersonal.gsmNo = this.personalAddressForm.value.gsmNumber;
            this.saasAddressPersonal.email = this.authService.account.email;
            this.saasAddressPersonal.active = true;
            this.saasAddressPersonal.ownerId = this.authService.account.id;
            this.saasAddressPersonal.identityNo = this.personalAddressForm.value.identityNumber;
            this.saasAddressPersonal.country = this.personalAddressForm.value.country;
            this.saasAddressPersonal.state = this.personalAddressForm.value.state;
            this.saasAddressPersonal.city = this.personalAddressForm.value.city;
            this.saasAddressPersonal.district = this.personalAddressForm.value.district;
            this.saasAddressPersonal.address = this.personalAddressForm.value.address;
            this.saasAddressPersonal.zipCode = this.personalAddressForm.value.zipCode;
            if (this.saasAddressPersonal.domainIds == null) {
                let domainIds: Array<string>;
                domainIds = [this.authService.account.domainIds[0]];
                console.log(this.authService.account.domainIds[0]);
                this.saasAddressPersonal.domainIds = domainIds;
            }
        }
    }

}


