import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    pathMatch: 'full'
  },
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  {
    path: 'tour/:id',
    loadChildren: () => import('./pages/tour/tour.module').then(m => m.TourPageModule)
  },
  {
    path: 'embed/:id',
    loadChildren: () => import('./pages/embed/embed.module').then(m => m.EmbedPageModule)
  },
  {
    path: 'embed/:id/:sectionId',
    loadChildren: () => import('./pages/embed/embed.module').then(m => m.EmbedPageModule)
  },
  {
    path: 'teknoloji',
    loadChildren: () => import('./pages/technology/technology.module').then(m => m.TechnologyPageModule)
  },
  {
    path: 'plans',
    loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'surec',
    loadChildren: () => import('./pages/process/process.module').then(m => m.ProcessPageModule)
  },
  {
    path: 'hizmet',
    loadChildren: () => import('./pages/service/service.module').then(m => m.ServicePageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/authorize/authorize.module').then(m => m.AuthorizePageModule),
  },
  {
    path: 'panel',
    loadChildren: () => import('./panel/panel.module').then(m => m.PanelPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'legal',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule)
  },
  {
    path: 'solutions',
    loadChildren: () => import('./pages/solutions/solutions.module').then(m => m.SolutionsPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'experience/:type/:id',
    loadChildren: () => import('./pages/experience/experience.module').then(m => m.ExperiencePageModule)
  },
  {
    path: 'open-ar/:id',
    loadChildren: () => import('./pages/open-ar/open-ar.module').then(m => m.OpenArPageModule)
  },
  {
    path: 'tours',
    loadChildren: () => import('./pages/tours/tours.module').then(m => m.ToursPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
