import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Storage } from '@capacitor/storage';
import { AuthService } from '../services/yon/auth/auth.service';
/** Simulate server replying to file upload request */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.token != null && this.auth.token !== undefined) {
      return next.handle(
        req.clone({
          headers: req.headers.append('Authorization', this.auth.token)
        })
      );
    }
    else {
      return from(this.handleAccess(req, next));
    }
  }

  private async handleAccess(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    return Storage.get({ key: 'Authorization' }).then(
      (token) => {
        if (token.value != null) {
          return next.handle(
            req.clone({
              headers: req.headers.append('Authorization', token.value)
            })
          ).toPromise();
        }
        else {
          return next.handle(req.clone()).toPromise();
        }
      }
    );
  }
}
