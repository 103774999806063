import { Request } from "src/app/domain/base/request";
import { Tour } from "../tour";

export class UpdateTourRequest extends Request {
    tour: Tour;

    constructor(tour: Tour) {
        super();
        this.tour = tour;
    }
}