import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { PageRequestDto } from "src/app/dto/request/page-request.dto";
import { HttpManager } from "src/app/managers/http.manager";
import { Observable } from 'rxjs';
import { SaasProduct } from 'src/app/domain/saas/saas-product';
import { GenericRequest } from 'src/app/dto/request/generic-request.dto';
import { parameters } from 'src/app/parameters/parameters';

const childPath = "product/";

@Injectable()
export class SaasProductService {
    constructor(private httpManager: HttpManager) {
    }

    create(product: SaasProduct): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "create", product).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    // update(tour: Tour): Observable<any> {
    //       return this.httpManager.post(parameters.server + childPath + "update", tour).pipe(
    //         map(
    //             (status) => {
    //                 if (status != null) {
    //                     return status;
    //                 }
    //                 else {
    //                     return null;
    //                 }
    //             }
    //         )
    //     );
    // }

    // delete(tour: Tour): Observable<any> {
    //     return this.httpManager.post(parameters.server + childPath + "delete", tour).pipe(
    //         map(
    //             (status) => {
    //                 if (status != null) {
    //                     return status;
    //                 }
    //                 else {
    //                     return null;
    //                 }
    //             }
    //         )
    //     );
    // }

    search(pageRequest: PageRequestDto) {
        return this.httpManager.post(parameters.server + childPath + "search", pageRequest).pipe(
            map(
                (status) => {
                    if (status != null) {
                        return status;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    list(pageRequest: PageRequestDto): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "list", pageRequest).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }

    getById(genericReq: GenericRequest): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "get-by-id", genericReq).pipe(
            map(
                (res) => {
                    if (res != null) {
                        return res;
                    }
                    else {
                        return null;
                    }
                }
            )
        );
    }
}