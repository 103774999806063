/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { QRCodeModule } from 'angularx-qrcode';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { DomainInterceptor } from './interceptors/domain.interceptor';
import { AppSlugInterceptor } from './interceptors/app-slug.interceptor';
import { DeviceInterceptor } from './interceptors/device.interceptor';
import { HttpManager } from './managers/http.manager';
import { AuthGuard } from './guards/auth.guard';
import { SEOService } from './services/seo.service';
import { TourService } from './services/experience/tour/tour.service';
import { ProductService } from './services/experience/product/product.service';
import { DirectoryService } from './services/yon/media/directory.service';
import { FileService } from './services/yon/media/file.service';
import { ConfigurationService } from './services/yon/configuration.service';
import { AuthService } from './services/yon/auth/auth.service';
import { InterfaceService } from './services/yon/auth/interface.service';
import { AccountService } from './services/yon/auth/account.service';
import { OrganizationService } from './services/yon/auth/organizations.service';
import { TypeService } from './services/yon/auth/type.service';
import { ItemService } from './services/yon/auth/item.service';
import { ContactService } from './services/contact.service';
import { DemandService } from './services/demand.service';
import { UrlHelperService } from './services/url.helper.service';
import { LanguageService } from './services/language.service';
import { CheckoutService } from './services/checkout.service';
import { SaasPricingPlanService } from './services/saas/saas-pricing-plan.service';
import { SaasProductService } from './services/saas/saas-product.service';
import { SaasSubscriptionService } from './services/saas/saas-subscription.service';
import { ExperienceService } from './services/experience/experience.service';
import { SecureService } from './services/secure.service';
import { PasswordStrengthComponent } from './components/auth/password/password-strength/password-strength.component';
import { LocaleInterceptor } from './interceptors/locale.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotspotPreviewModalModule } from './modals/hotspot-preview/hotspot-preview.module';
import { AddressModalModule } from './panel/modal/address/address.module';
import { NavbarModule } from './components/navbar/navbar.module';
import { SectionsModalModule } from './modals/sections/sections.module';
import { QrcodeScreenModalModule } from './modals/qrcode-screen/qrcode-screen.module';
import { NgProgressModule } from 'ngx-progressbar';
import { ContactSalesModalModule } from './modals/contact-sales/contact-sales.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({
      mode: 'ios',
      backButtonText: ''
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    NgProgressModule.withConfig({
      trickleSpeed: 200,
      min: 20,
      meteor: false,
      spinner: false,
      color: '#d53815'
    }),
    AppRoutingModule,
    HttpClientModule,
    NavbarModule,
    SectionsModalModule,
    QrcodeScreenModalModule,
    QRCodeModule,
    AddressModalModule,
    HotspotPreviewModalModule,
    ContactSalesModalModule
  ],
  providers: [
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DomainInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppSlugInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeviceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true
    },
    JwtHelperService,
    HttpManager,
    AuthGuard,
    SEOService,
    TourService,
    ProductService,
    DirectoryService,
    FileService,
    ConfigurationService,
    AuthService,
    InterfaceService,
    AccountService,
    OrganizationService,
    TypeService,
    ItemService,
    ContactService,
    DemandService,
    UrlHelperService,
    LanguageService,
    CheckoutService,
    SaasPricingPlanService,
    SaasProductService,
    SaasSubscriptionService,
    ExperienceService,
    SecureService,
    PasswordStrengthComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
export function tokenGetter() {
  return localStorage.getItem('Authorization');
}
