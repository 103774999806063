import { Request } from "../../base/request";
import { SubscriptionCardData } from "../../iyzico/subscription-card-data";
import { SaasAddress } from "../saas-address";
import { SaasSubscription } from "../saas-subscription";

export class InitializeSaasSubscriptionRequest extends Request {
  subscriptionCardData: SubscriptionCardData;
  saasAddress: SaasAddress;
  saasSubscription: SaasSubscription;


  constructor(subscriptionCardData: SubscriptionCardData, saasAddress: SaasAddress, saasSubscription: SaasSubscription) {
    super();
    this.subscriptionCardData = subscriptionCardData;
    this.saasAddress = saasAddress;
    this.saasSubscription = saasSubscription;
  }

};
