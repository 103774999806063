import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CreateSaasAddressRequest } from "src/app/domain/saas/request/saas-address/create-saas-address-request";
import { DeleteSaasAddressRequest } from "src/app/domain/saas/request/saas-address/delete-saas-address-request";
import { RetrieveSaasAddressListWithOwnerIdRequest } from "src/app/domain/saas/request/saas-address/retrieve-saas-address-list-with-owner-id-request";
import { UpdateSaasAddressRequest } from "src/app/domain/saas/request/saas-address/update-saas-address-request";
import { SaasAddress } from "src/app/domain/saas/saas-address";
import { HttpManager } from "src/app/managers/http.manager";
import { parameters } from "src/app/parameters/parameters";

const childPath = "saas-address/";

@Injectable()
export class SaasAddressService {
    constructor(private httpManager: HttpManager) {
    }

    create(saasAddress: SaasAddress): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "create", new CreateSaasAddressRequest(saasAddress)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        );
    }

    update(saasAddress: SaasAddress): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "update", new UpdateSaasAddressRequest(saasAddress)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        );
    }

    delete(id: string): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "delete", new DeleteSaasAddressRequest(id)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        );
    }

    getByOwnerId(
        ownerId: string): Observable<any> {
        return this.httpManager.post(parameters.server + childPath + "get-by-owner-id", new RetrieveSaasAddressListWithOwnerIdRequest(ownerId)).pipe(
            map(
                (response: Response) => {
                    return response;
                }
            )
        );
    }
}