import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { ContactSalesModalRoutingModule } from './contact-sales-routing.module';
import { ContactSalesModal } from './contact-sales.modal';


// import { PinchZoomModule } from 'ngx-pinch-zoom';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ContactSalesModalRoutingModule,
    // PinchZoomModule
  ],
  declarations: [ContactSalesModal]
})
export class ContactSalesModalModule {}
