import { Section } from 'src/app/domain/experience/tour/section';
import { Component, Input, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.modal.html',
  styleUrls: ['./sections.modal.scss'],
})
export class SectionsModal implements OnInit, AfterViewInit {

  @Input("sections") sections: Section[];
  @Input("currentSectionIndex") currentSectionIndex: number;

  @ViewChild('slider', { static: false })
  public slider: IonSlides;

  public sliderIndex: number = 0;

  initiliazed: boolean = false;

  public slideOpts = {
    centeredSlides: true,
    //speed: 300,
    //freeMode: true,
    //freeModeSticky: true,
    //speed: 400,
    //freeModeMomentum: false,
    //freeModeMinimumVelocity: 0.1,
    // Responsive breakpoints
    spaceBetween: 2.5,
    // slidesOffsetBefore: 16,
    // slidesOffsetAfter: 16,
    //centeredSlides: true,
    initialSlide: 0,
    mousewheel: {
      enabled: true,
      invert: true,
      forceToAxis: true
    },
    scrollbar: {
      enabled: true,
      el: ".swiper-scrollbar",
      hide: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      '@1.0': {
        slidesPerView: 3.3,
      },
      '@2.16': {
        slidesPerView: 4.3,
      },
      200: {
        slidesPerView: 1.2,
      },
      767: {
        slidesPerView: 1.8,
      },
      1023: {
        slidesPerView: 3.8,
      },
      1441: {
        slidesPerView: 4.5,
      }
    }
  }

  constructor(private modalController: ModalController) {
    this.sliderIndex = this.currentSectionIndex;
  }

  ngAfterViewInit(): void {


    console.log("ngAfterViewInit")
  }

  ngOnInit() {
    console.log("ngOnInit")
  }

  ionViewDidEnter() {
    this.slider.update().then(
      (val) => {
        this.slider.options.initialSlide = this.currentSectionIndex
        this.slider.slideTo(this.currentSectionIndex)
      }
    );
    this.slider.slideTo(this.currentSectionIndex);
    this.slider.options = this.slideOpts;
    this.slider.options.mousewheel.enabled = true;
    this.slider.getSwiper().then(
      (swiper) => {
        //console.log(swiper)
      }

    )
  }

  ionViewWillEnter() {

  }

  close(index) {
    this.modalController.dismiss(index);
  }

  slidesChanged(event) {
    this.slider.getActiveIndex().then(
      (index) => {
        this.sliderIndex = index;
      }
    )
  }

  @HostListener("window:click", ['$event'])
  universalClick(e) {
    console.log(e)
    if (this.initiliazed) {
      if (e.target.className.includes("content-container") || e.target.localName == "ion-toolbar")
        this.modalController.dismiss(null);
    }
    else {
      this.initiliazed = true;
    }
  }
}
