import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollDirective } from './scroll.directive';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        RouterModule
    ],
    declarations: [ScrollDirective],
    exports: [ScrollDirective]
})

export class ScrollDirectiveModule { }